@charset "UTF-8";
/*      light colors - used for select dropdown         */
/*           Font Smoothing      */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, a, .td-name, td, button, input, select, textarea {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Montserrat", "Helvetica", Arial, sans-serif;
  font-weight: 300;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  margin: 30px 0 0;
}

h1, .h1 {
  font-size: 40px;
  color: #722F37 !important;
}

h2, .h2 {
  font-size: 2.8em;
}

h3, .h3 {
  font-size: 1.825em;
  line-height: 1.4;
  margin: 20px 0 0px;
}

h4, .h4 {
  font-size: 1.6em;
  line-height: 1.2em;
}

h5, .h5 {
  font-size: 1.35em;
  line-height: 1.4em;
}

h6, .h6 {
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
}

p {
  font-size: 15px;
  line-height: 1.5em;
  margin-bottom: 5px;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  color: #9A9A9A;
  line-height: 1.5em;
}

h1 small, h2 small, h3 small, h1 .small, h2 .small, h3 .small {
  font-size: 60%;
}

.title,
.card-title,
.info-title,
.footer-brand,
.footer-big h5,
.footer-big h4,
.media .media-heading {
  font-family: "Montserrat", "Helvetica", Arial, sans-serif;
}
.title,
.title a,
.card-title,
.card-title a,
.info-title,
.info-title a,
.footer-brand,
.footer-brand a,
.footer-big h5,
.footer-big h5 a,
.footer-big h4,
.footer-big h4 a,
.media .media-heading,
.media .media-heading a {
  color: #333333;
  text-decoration: none;
}

.title-uppercase {
  text-transform: uppercase;
}

.description {
  color: #9A9A9A;
}

blockquote small {
  font-style: normal;
}

.text-muted {
  color: #DDDDDD;
}

.text-primary, .text-primary:hover {
  color: #51cbce !important;
}

.text-info, .text-info:hover {
  color: #51bcda !important;
}

.text-success, .text-success:hover {
  color: #6bd098 !important;
}

.text-warning, .text-warning:hover {
  color: #fbc658 !important;
}

.text-danger, .text-danger:hover {
  color: #f5593d !important;
}

.glyphicon {
  line-height: 1;
}

.heart {
  color: #EB5E28;
  animation: heathing 1s ease infinite;
}

@keyframes heathing {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
.footer .credits,
.footer-nav {
  line-height: 85px;
}

.footer .btn {
  margin-bottom: 0;
}

.blockquote {
  border-left: 0 none;
  border-bottom: 1px solid #CCC5B9;
  border-top: 1px solid #CCC5B9;
  font-weight: 300;
  margin: 15px 0 10px;
  text-align: center;
}

.title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
}

.title.text-center {
  margin-bottom: 50px;
}

/*     General overwrite     */
body {
  color: #66615b;
  font-size: 14px;
  font-weight: 300;
  font-family: "Montserrat", "Helvetica", Arial, sans-serif;
}

a {
  color: #51bcda;
}
a:hover, a:focus {
  color: #2ba9cd;
  text-decoration: none;
}

hr {
  border-color: #F1EAE0;
}

.icon {
  fill: #66615b;
}

.fa-base {
  font-size: 1.25em !important;
}

a:focus, a:active,
button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
select::-moz-focus-inner,
input[type=file] > input[type=button]::-moz-focus-inner {
  outline: 0;
}

.ui-slider-handle:focus,
.navbar-toggle {
  outline: 0 !important;
}

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert,
.carousel-control.right,
.carousel-control.left {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.tagsinput .tag,
.tagsinput-remove-link,
.filter,
.btn-hover,
[data-toggle=collapse] i,
.animation-transition-fast,
.dropdown-menu .dropdown-item {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
}

.btn-morphing .fa,
.btn-morphing .circle,
.gsdk-collapse {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.fa {
  width: 18px;
  text-align: center;
}

.margin-top {
  margin-top: 50px;
}

.iframe-container iframe {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

/*       CT colors          */
.ct-blue {
  color: #51cbce;
}

.ct-azure {
  color: #51bcda;
}

.ct-green {
  color: #6bd098;
}

.ct-orange {
  color: #fbc658;
}

.ct-red {
  color: #f5593d;
}

.pagination .page-item .page-link .fa {
  width: auto;
  font-weight: 600;
}

.bg-primary {
  background-color: #6dd3d6 !important;
}

.bg-info {
  background-color: #6ec7e0 !important;
}

.bg-success {
  background-color: #86d9ab !important;
}

.bg-warning {
  background-color: #fcd27b !important;
}

.bg-danger {
  background-color: #f7765f !important;
}

.navbar-transparent {
  background-color: transparent !important;
  border-bottom: 1px solid transparent;
}

.btn {
  box-sizing: border-box;
  border-width: 2px;
  font-size: 14px;
  font-weight: 600;
  padding: 0.5rem 18px;
  line-height: 1.75;
  margin-right: 3px;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #722F37;
  border-color: #722F37;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
}
.btn.btn-border, .btn.btn-link {
  background-color: transparent;
}
.btn:hover, .btn:focus, .btn:active, .btn.active, .show > .btn.dropdown-toggle {
  background-color: #403D39;
  color: #FFFFFF;
  border-color: #403D39;
}
.btn .caret {
  border-top-color: #FFFFFF;
}
.btn.btn-link {
  color: #722F37;
}
.btn.btn-link:hover, .btn.btn-link:focus, .btn.btn-link:active, .btn.btn-link.active, .open > .btn.btn-link.dropdown-toggle {
  background-color: transparent;
  color: #403D39;
}
.btn.btn-link .caret {
  border-top-color: #722F37;
}
.btn .caret {
  border-top-color: #FFFFFF;
}
.btn:hover, .btn:focus {
  outline: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn:active, .btn.active, .open > .btn.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
}
.btn[class*=btn-outline-] {
  background-image: none;
  background-color: transparent;
}

.btn-just-icon {
  border-radius: 50px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  padding: 8px;
}
.btn-just-icon.btn-sm {
  padding: 4px !important;
}
.btn-just-icon i {
  font-size: 16px;
  padding: 2px 0px;
}

.upgrade-pro .btn {
  margin-top: 30px;
}

.btn-link.btn-just-icon {
  padding: 8px;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -2px;
}

.btn-primary {
  background-color: #51cbce;
  border-color: #51cbce;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  background-color: #34b5b8;
  color: #FFFFFF;
  border-color: #34b5b8;
}
.btn-primary .caret {
  border-top-color: #FFFFFF;
}
.btn-primary.btn-link {
  color: #51cbce;
}
.btn-primary.btn-link:hover, .btn-primary.btn-link:focus, .btn-primary.btn-link:active, .btn-primary.btn-link.active, .open > .btn-primary.btn-link.dropdown-toggle {
  background-color: transparent;
  color: #34b5b8;
}
.btn-primary.btn-link .caret {
  border-top-color: #51cbce;
}
.btn-primary .caret {
  border-top-color: #FFFFFF;
}

.btn-success {
  background-color: #6bd098;
  border-color: #6bd098;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  background-color: #44c47d;
  color: #FFFFFF;
  border-color: #44c47d;
}
.btn-success .caret {
  border-top-color: #FFFFFF;
}
.btn-success.btn-link {
  color: #6bd098;
}
.btn-success.btn-link:hover, .btn-success.btn-link:focus, .btn-success.btn-link:active, .btn-success.btn-link.active, .open > .btn-success.btn-link.dropdown-toggle {
  background-color: transparent;
  color: #44c47d;
}
.btn-success.btn-link .caret {
  border-top-color: #6bd098;
}
.btn-success .caret {
  border-top-color: #FFFFFF;
}

.btn-info {
  background-color: #51bcda;
  border-color: #51bcda;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  background-color: #2ba9cd;
  color: #FFFFFF;
  border-color: #2ba9cd;
}
.btn-info .caret {
  border-top-color: #FFFFFF;
}
.btn-info.btn-link {
  color: #51bcda;
}
.btn-info.btn-link:hover, .btn-info.btn-link:focus, .btn-info.btn-link:active, .btn-info.btn-link.active, .open > .btn-info.btn-link.dropdown-toggle {
  background-color: transparent;
  color: #2ba9cd;
}
.btn-info.btn-link .caret {
  border-top-color: #51bcda;
}
.btn-info .caret {
  border-top-color: #FFFFFF;
}

.btn-warning {
  background-color: #fbc658;
  border-color: #fbc658;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  background-color: #fab526;
  color: #FFFFFF;
  border-color: #fab526;
}
.btn-warning .caret {
  border-top-color: #FFFFFF;
}
.btn-warning.btn-link {
  color: #fbc658;
}
.btn-warning.btn-link:hover, .btn-warning.btn-link:focus, .btn-warning.btn-link:active, .btn-warning.btn-link.active, .open > .btn-warning.btn-link.dropdown-toggle {
  background-color: transparent;
  color: #fab526;
}
.btn-warning.btn-link .caret {
  border-top-color: #fbc658;
}
.btn-warning .caret {
  border-top-color: #FFFFFF;
}

.btn-danger {
  background-color: #f5593d;
  border-color: #f5593d;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  background-color: #f33816;
  color: #FFFFFF;
  border-color: #f33816;
}
.btn-danger .caret {
  border-top-color: #FFFFFF;
}
.btn-danger.btn-link {
  color: #f5593d;
}
.btn-danger.btn-link:hover, .btn-danger.btn-link:focus, .btn-danger.btn-link:active, .btn-danger.btn-link.active, .open > .btn-danger.btn-link.dropdown-toggle {
  background-color: transparent;
  color: #f33816;
}
.btn-danger.btn-link .caret {
  border-top-color: #f5593d;
}
.btn-danger .caret {
  border-top-color: #FFFFFF;
}

.btn-neutral {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active, .btn-neutral.active, .show > .btn-neutral.dropdown-toggle {
  background-color: #403D39;
  color: #FFFFFF;
  border-color: #403D39;
}
.btn-neutral .caret {
  border-top-color: #FFFFFF;
}
.btn-neutral.btn-link {
  color: #FFFFFF;
}
.btn-neutral.btn-link:hover, .btn-neutral.btn-link:focus, .btn-neutral.btn-link:active, .btn-neutral.btn-link.active, .open > .btn-neutral.btn-link.dropdown-toggle {
  background-color: transparent;
  color: #403D39;
}
.btn-neutral.btn-link .caret {
  border-top-color: #FFFFFF;
}
.btn-neutral .caret {
  border-top-color: #FFFFFF;
}

.btn-outline-default {
  border-color: #722F37;
  color: #722F37;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-outline-default:hover, .btn-outline-default:focus, .btn-outline-default:active, .btn-outline-default.active, .open > .btn-outline-default.dropdown-toggle {
  background-color: #722F37;
  color: rgba(255, 255, 255, 0.8);
  border-color: #722F37;
}
.btn-outline-default:hover .caret, .btn-outline-default:focus .caret, .btn-outline-default:active .caret, .btn-outline-default.active .caret, .open > .btn-outline-default.dropdown-toggle .caret {
  border-top-color: rgba(255, 255, 255, 0.8);
}
.btn-outline-default .caret {
  border-top-color: #FFFFFF;
}
.btn-outline-default.disabled, .btn-outline-default.disabled:hover, .btn-outline-default.disabled:focus, .btn-outline-default.disabled.focus, .btn-outline-default.disabled:active, .btn-outline-default.disabled.active, .btn-outline-default:disabled, .btn-outline-default:disabled:hover, .btn-outline-default:disabled:focus, .btn-outline-default:disabled.focus, .btn-outline-default:disabled:active, .btn-outline-default:disabled.active, .btn-outline-default[disabled], .btn-outline-default[disabled]:hover, .btn-outline-default[disabled]:focus, .btn-outline-default[disabled].focus, .btn-outline-default[disabled]:active, .btn-outline-default[disabled].active, fieldset[disabled] .btn-outline-default, fieldset[disabled] .btn-outline-default:hover, fieldset[disabled] .btn-outline-default:focus, fieldset[disabled] .btn-outline-default.focus, fieldset[disabled] .btn-outline-default:active, fieldset[disabled] .btn-outline-default.active {
  background-color: transparent;
  border-color: #722F37;
}

.btn-outline-primary {
  border-color: #51cbce;
  color: #51cbce;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .open > .btn-outline-primary.dropdown-toggle {
  background-color: #51cbce;
  color: rgba(255, 255, 255, 0.8);
  border-color: #51cbce;
}
.btn-outline-primary:hover .caret, .btn-outline-primary:focus .caret, .btn-outline-primary:active .caret, .btn-outline-primary.active .caret, .open > .btn-outline-primary.dropdown-toggle .caret {
  border-top-color: rgba(255, 255, 255, 0.8);
}
.btn-outline-primary .caret {
  border-top-color: #FFFFFF;
}
.btn-outline-primary.disabled, .btn-outline-primary.disabled:hover, .btn-outline-primary.disabled:focus, .btn-outline-primary.disabled.focus, .btn-outline-primary.disabled:active, .btn-outline-primary.disabled.active, .btn-outline-primary:disabled, .btn-outline-primary:disabled:hover, .btn-outline-primary:disabled:focus, .btn-outline-primary:disabled.focus, .btn-outline-primary:disabled:active, .btn-outline-primary:disabled.active, .btn-outline-primary[disabled], .btn-outline-primary[disabled]:hover, .btn-outline-primary[disabled]:focus, .btn-outline-primary[disabled].focus, .btn-outline-primary[disabled]:active, .btn-outline-primary[disabled].active, fieldset[disabled] .btn-outline-primary, fieldset[disabled] .btn-outline-primary:hover, fieldset[disabled] .btn-outline-primary:focus, fieldset[disabled] .btn-outline-primary.focus, fieldset[disabled] .btn-outline-primary:active, fieldset[disabled] .btn-outline-primary.active {
  background-color: transparent;
  border-color: #51cbce;
}

.btn-outline-success {
  border-color: #6bd098;
  color: #6bd098;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active, .open > .btn-outline-success.dropdown-toggle {
  background-color: #6bd098;
  color: rgba(255, 255, 255, 0.8);
  border-color: #6bd098;
}
.btn-outline-success:hover .caret, .btn-outline-success:focus .caret, .btn-outline-success:active .caret, .btn-outline-success.active .caret, .open > .btn-outline-success.dropdown-toggle .caret {
  border-top-color: rgba(255, 255, 255, 0.8);
}
.btn-outline-success .caret {
  border-top-color: #FFFFFF;
}
.btn-outline-success.disabled, .btn-outline-success.disabled:hover, .btn-outline-success.disabled:focus, .btn-outline-success.disabled.focus, .btn-outline-success.disabled:active, .btn-outline-success.disabled.active, .btn-outline-success:disabled, .btn-outline-success:disabled:hover, .btn-outline-success:disabled:focus, .btn-outline-success:disabled.focus, .btn-outline-success:disabled:active, .btn-outline-success:disabled.active, .btn-outline-success[disabled], .btn-outline-success[disabled]:hover, .btn-outline-success[disabled]:focus, .btn-outline-success[disabled].focus, .btn-outline-success[disabled]:active, .btn-outline-success[disabled].active, fieldset[disabled] .btn-outline-success, fieldset[disabled] .btn-outline-success:hover, fieldset[disabled] .btn-outline-success:focus, fieldset[disabled] .btn-outline-success.focus, fieldset[disabled] .btn-outline-success:active, fieldset[disabled] .btn-outline-success.active {
  background-color: transparent;
  border-color: #6bd098;
}

.btn-outline-info {
  border-color: #51bcda;
  color: #51bcda;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active, .open > .btn-outline-info.dropdown-toggle {
  background-color: #51bcda;
  color: rgba(255, 255, 255, 0.8);
  border-color: #51bcda;
}
.btn-outline-info:hover .caret, .btn-outline-info:focus .caret, .btn-outline-info:active .caret, .btn-outline-info.active .caret, .open > .btn-outline-info.dropdown-toggle .caret {
  border-top-color: rgba(255, 255, 255, 0.8);
}
.btn-outline-info .caret {
  border-top-color: #FFFFFF;
}
.btn-outline-info.disabled, .btn-outline-info.disabled:hover, .btn-outline-info.disabled:focus, .btn-outline-info.disabled.focus, .btn-outline-info.disabled:active, .btn-outline-info.disabled.active, .btn-outline-info:disabled, .btn-outline-info:disabled:hover, .btn-outline-info:disabled:focus, .btn-outline-info:disabled.focus, .btn-outline-info:disabled:active, .btn-outline-info:disabled.active, .btn-outline-info[disabled], .btn-outline-info[disabled]:hover, .btn-outline-info[disabled]:focus, .btn-outline-info[disabled].focus, .btn-outline-info[disabled]:active, .btn-outline-info[disabled].active, fieldset[disabled] .btn-outline-info, fieldset[disabled] .btn-outline-info:hover, fieldset[disabled] .btn-outline-info:focus, fieldset[disabled] .btn-outline-info.focus, fieldset[disabled] .btn-outline-info:active, fieldset[disabled] .btn-outline-info.active {
  background-color: transparent;
  border-color: #51bcda;
}

.btn-outline-warning {
  border-color: #fbc658;
  color: #fbc658;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active, .open > .btn-outline-warning.dropdown-toggle {
  background-color: #fbc658;
  color: rgba(255, 255, 255, 0.8);
  border-color: #fbc658;
}
.btn-outline-warning:hover .caret, .btn-outline-warning:focus .caret, .btn-outline-warning:active .caret, .btn-outline-warning.active .caret, .open > .btn-outline-warning.dropdown-toggle .caret {
  border-top-color: rgba(255, 255, 255, 0.8);
}
.btn-outline-warning .caret {
  border-top-color: #FFFFFF;
}
.btn-outline-warning.disabled, .btn-outline-warning.disabled:hover, .btn-outline-warning.disabled:focus, .btn-outline-warning.disabled.focus, .btn-outline-warning.disabled:active, .btn-outline-warning.disabled.active, .btn-outline-warning:disabled, .btn-outline-warning:disabled:hover, .btn-outline-warning:disabled:focus, .btn-outline-warning:disabled.focus, .btn-outline-warning:disabled:active, .btn-outline-warning:disabled.active, .btn-outline-warning[disabled], .btn-outline-warning[disabled]:hover, .btn-outline-warning[disabled]:focus, .btn-outline-warning[disabled].focus, .btn-outline-warning[disabled]:active, .btn-outline-warning[disabled].active, fieldset[disabled] .btn-outline-warning, fieldset[disabled] .btn-outline-warning:hover, fieldset[disabled] .btn-outline-warning:focus, fieldset[disabled] .btn-outline-warning.focus, fieldset[disabled] .btn-outline-warning:active, fieldset[disabled] .btn-outline-warning.active {
  background-color: transparent;
  border-color: #fbc658;
}

.btn-outline-danger {
  border-color: #f5593d;
  color: #f5593d;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active, .open > .btn-outline-danger.dropdown-toggle {
  background-color: #f5593d;
  color: rgba(255, 255, 255, 0.8);
  border-color: #f5593d;
}
.btn-outline-danger:hover .caret, .btn-outline-danger:focus .caret, .btn-outline-danger:active .caret, .btn-outline-danger.active .caret, .open > .btn-outline-danger.dropdown-toggle .caret {
  border-top-color: rgba(255, 255, 255, 0.8);
}
.btn-outline-danger .caret {
  border-top-color: #FFFFFF;
}
.btn-outline-danger.disabled, .btn-outline-danger.disabled:hover, .btn-outline-danger.disabled:focus, .btn-outline-danger.disabled.focus, .btn-outline-danger.disabled:active, .btn-outline-danger.disabled.active, .btn-outline-danger:disabled, .btn-outline-danger:disabled:hover, .btn-outline-danger:disabled:focus, .btn-outline-danger:disabled.focus, .btn-outline-danger:disabled:active, .btn-outline-danger:disabled.active, .btn-outline-danger[disabled], .btn-outline-danger[disabled]:hover, .btn-outline-danger[disabled]:focus, .btn-outline-danger[disabled].focus, .btn-outline-danger[disabled]:active, .btn-outline-danger[disabled].active, fieldset[disabled] .btn-outline-danger, fieldset[disabled] .btn-outline-danger:hover, fieldset[disabled] .btn-outline-danger:focus, fieldset[disabled] .btn-outline-danger.focus, fieldset[disabled] .btn-outline-danger:active, fieldset[disabled] .btn-outline-danger.active {
  background-color: transparent;
  border-color: #f5593d;
}

.btn-outline-neutral {
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-outline-neutral:hover, .btn-outline-neutral:focus, .btn-outline-neutral:active, .btn-outline-neutral.active, .open > .btn-outline-neutral.dropdown-toggle {
  background-color: #FFFFFF;
  color: rgba(255, 255, 255, 0.8);
  border-color: #FFFFFF;
}
.btn-outline-neutral:hover .caret, .btn-outline-neutral:focus .caret, .btn-outline-neutral:active .caret, .btn-outline-neutral.active .caret, .open > .btn-outline-neutral.dropdown-toggle .caret {
  border-top-color: rgba(255, 255, 255, 0.8);
}
.btn-outline-neutral .caret {
  border-top-color: #FFFFFF;
}
.btn-outline-neutral.disabled, .btn-outline-neutral.disabled:hover, .btn-outline-neutral.disabled:focus, .btn-outline-neutral.disabled.focus, .btn-outline-neutral.disabled:active, .btn-outline-neutral.disabled.active, .btn-outline-neutral:disabled, .btn-outline-neutral:disabled:hover, .btn-outline-neutral:disabled:focus, .btn-outline-neutral:disabled.focus, .btn-outline-neutral:disabled:active, .btn-outline-neutral:disabled.active, .btn-outline-neutral[disabled], .btn-outline-neutral[disabled]:hover, .btn-outline-neutral[disabled]:focus, .btn-outline-neutral[disabled].focus, .btn-outline-neutral[disabled]:active, .btn-outline-neutral[disabled].active, fieldset[disabled] .btn-outline-neutral, fieldset[disabled] .btn-outline-neutral:hover, fieldset[disabled] .btn-outline-neutral:focus, fieldset[disabled] .btn-outline-neutral.focus, fieldset[disabled] .btn-outline-neutral:active, fieldset[disabled] .btn-outline-neutral.active {
  background-color: transparent;
  border-color: #FFFFFF;
}
.btn-outline-neutral:hover, .btn-outline-neutral:focus {
  color: #403D39;
  background-color: #FFFFFF;
}

.btn-neutral {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #722F37;
}
.btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active, .btn-neutral.active, .show > .btn-neutral.dropdown-toggle {
  background-color: #FFFFFF;
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.btn-neutral .caret {
  border-top-color: #FFFFFF;
}
.btn-neutral.btn-link {
  color: #FFFFFF;
}
.btn-neutral.btn-link:hover, .btn-neutral.btn-link:focus, .btn-neutral.btn-link:active, .btn-neutral.btn-link.active, .open > .btn-neutral.btn-link.dropdown-toggle {
  background-color: transparent;
  color: #FFFFFF;
}
.btn-neutral.btn-link .caret {
  border-top-color: #FFFFFF;
}
.btn-neutral .caret {
  border-top-color: #FFFFFF;
}
.btn-neutral:hover, .btn-neutral:focus {
  color: #403D39;
}
.btn-neutral.btn-border:hover, .btn-neutral.btn-border:focus {
  color: #722F37;
}
.btn-neutral.btn-border:active, .btn-neutral.btn-border.active, .open > .btn-neutral.btn-border.dropdown-toggle {
  background-color: #FFFFFF;
  color: #722F37;
}
.btn-neutral.btn-link:active, .btn-neutral.btn-link.active {
  background-color: transparent;
}

.btn:disabled, .btn[disabled], .btn.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.btn-link {
  border-color: transparent !important;
  padding: 7px 18px;
}
.btn-link:hover, .btn-link:focus, .btn-link:active {
  text-decoration: none;
  border-color: transparent;
}
.btn-link.btn-icon {
  padding: 7px;
}

.btn-lg {
  font-size: 14px;
  padding: 11px 30px;
}
.btn-lg.btn-simple {
  padding: 13px 30px;
}

.btn-sm {
  font-size: 14px;
  padding: 4px 10px;
}
.btn-sm.btn-simple {
  padding: 6px 10px;
}

.btn-wd {
  min-width: 140px;
}

.btn-group.select {
  width: 100%;
}

.btn-group.select .btn {
  text-align: left;
}

.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}

.btn-just-icon.btn-sm {
  height: 30px;
  width: 30px;
  min-width: 30px;
  padding: 0;
}
.btn-just-icon.btn-sm i {
  font-size: 14px;
}
.btn-just-icon.btn-lg {
  height: 50px;
  width: 50px;
  min-width: 50px;
  padding: 13px;
}
.btn-just-icon.btn-lg i {
  font-size: 18px;
  padding: 0;
}

.btn-round {
  border-radius: 30px;
}

.btn.btn-link:focus {
  box-shadow: none !important;
  text-decoration: none;
}

.column .btn-link {
  padding: 7px 0;
}

.share-buttons .btn-outline-default {
  margin-top: 24px;
}

#modals .btn-outline-neutral {
  margin-bottom: 10px;
}

.btn-group.select {
  overflow: visible !important;
}

.media .media-body .media-footer .btn-neutral {
  margin: 15px 3px;
  font-size: 14px;
}
.media .media-body .media-footer .btn-neutral i {
  margin-right: 0 !important;
}

.form-control::-moz-placeholder {
  color: #d2d2d2;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control:-moz-placeholder {
  color: #d2d2d2;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control::-webkit-input-placeholder {
  color: #d2d2d2;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control:-ms-input-placeholder {
  color: #d2d2d2;
  opacity: 1;
  filter: alpha(opacity=100);
}

.form-control {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  color: #66615b;
  font-size: 14px;
  padding: 7px 12px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control:focus {
  background-color: #FFFFFF;
  border: 1px solid #9A9A9A;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
}
.form-control.no-border {
  border: medium none !important;
}
.has-success .form-control, .has-error .form-control, .has-success .form-control:focus, .has-error .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .form-control {
  border: 1px solid #ccc;
  color: #66615b;
}
.has-success .form-control:focus {
  border: 1px solid #6bd098;
  color: #6bd098;
}
.has-danger .form-control {
  background-color: #FFC0A4;
  border: 1px solid #f5593d;
  color: #f5593d;
}
.has-danger .form-control:focus {
  background-color: #FFFFFF;
  border: 1px solid #f5593d;
}
.form-control + .form-control-feedback {
  border-radius: 6px;
  font-size: 14px;
  color: #f5593d;
  font-size: 0.8rem;
  position: absolute;
  top: 100%;
  padding-left: 12px;
  vertical-align: middle;
}
.open .form-control {
  border-radius: 4px 4px 0 0;
  border-bottom-color: transparent;
}

.input-lg {
  height: 55px;
  padding: 11px 30px;
}

.has-error .form-control-feedback, .has-error .control-label {
  color: #f5593d;
}

.has-success .form-control-feedback, .has-success .control-label {
  color: #6bd098;
}

.input-group .form-control:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none;
  padding-right: 0;
}

.input-group .form-control:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  border-left: 0 none;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #E3E3E3;
  color: #722F37;
  cursor: not-allowed;
}

.input-group-btn .btn {
  border-width: 1px;
  padding: 9px 18px;
}

.input-group-btn .btn-default:not(.btn-fill) {
  border-color: #DDDDDD;
}

.input-group-btn:last-child > .btn {
  margin-left: 0;
}

textarea.form-control {
  padding: 10px 18px;
  height: auto;
}

.form-group {
  position: relative;
}

.register-form .form-control {
  border: 1px solid transparent !important;
}

#inputs .input-group {
  margin-bottom: 1rem;
}

.card-form-horizontal .card-block .form-group {
  margin-bottom: 0;
}

.input-group {
  background: #FFFFFF;
}
.input-group .input-group-append .input-group-text {
  background: transparent;
  border: 1px solid #DDDDDD;
  border-left: none;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}
.input-group .input-group-prepend .input-group-text {
  margin-right: 0;
  background: transparent;
  border: 1px solid #DDDDDD;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}
.input-group.input-group-focus .input-group-prepend .input-group-text {
  border: 1px solid #9A9A9A !important;
}
.input-group.input-group-focus .input-group-append .input-group-text {
  border: 1px solid #9A9A9A !important;
}
.input-group.form-group-no-border .input-group-prepend .input-group-text {
  border-right: transparent !important;
  background: #FFFFFF !important;
  border: 1px solid #FFFFFF !important;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}
.input-group.form-group-no-border .input-group-append .input-group-text {
  border-left: transparent !important;
  background: #FFFFFF !important;
  border: 1px solid #FFFFFF !important;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.progress {
  background-color: #DDDDDD;
  border-radius: 3px;
  box-shadow: none;
  height: 15px;
}

.progress-thin {
  height: 4px;
}

.progress-bar {
  background-color: #51cbce;
}

.progress-bar-primary {
  background-color: #51cbce;
}

.progress-bar-info {
  background-color: #51bcda;
}

.progress-bar-success {
  background-color: #6bd098;
}

.progress-bar-warning {
  background-color: #fbc658;
}

.progress-bar-danger {
  background-color: #f5593d;
}

/*! nouislider - 14.6.3 - 11/19/2020 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 2px;
  margin-top: -1px;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}

.noUi-connect {
  height: 100%;
  width: 100%;
  margin-right: -6px;
}

.noUi-origin {
  height: 10%;
  width: 10%;
}

/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-touch-area:focus {
  outline: none;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 1px;
}

.noUi-horizontal .noUi-handle {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  right: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3FB8AF;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border: 1px solid #7ac29a;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.noUi-handle:focus {
  outline: none;
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
  transform: scale3d(2, 2, 1);
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}

.slider.slider-info .noUi-origin {
  background-color: #51cbce;
}

.slider.slider-info .noUi-handle {
  border-color: #51cbce;
}

.slider.slider-success .noUi-connect,
.slider.slider-success.noUi-connect {
  background-color: #51cbce;
}

.slider.slider-success .noUi-handle {
  border-color: #51cbce;
}

.slider.slider-warning .noUi-connect,
.slider.slider-warning.noUi-connect {
  background-color: #ff9800;
}

.slider.slider-warning .noUi-handle {
  border-color: #ff9800;
}

.slider.slider-danger .noUi-connect,
.slider.slider-danger.noUi-connect {
  background-color: #f44336;
}

.slider.slider-danger .noUi-handle {
  border-color: #f44336;
}

.alert {
  border: 0;
  border-radius: 0;
  color: #FFFFFF;
  padding: 10px 15px;
  font-size: 14px;
}
.alert .close {
  font-size: 20px;
  color: #FFFFFF;
}
.container .alert {
  border-radius: 4px;
}
.navbar .alert {
  border-radius: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 85px;
  width: 100%;
  z-index: 3;
}
.navbar:not(.navbar-transparent) .alert {
  top: 70px;
}
.alert .alert-icon {
  display: block;
  font-size: 30px;
  left: 15px;
  position: absolute;
  top: 50%;
  margin-top: -22px;
}
.alert.message {
  padding-right: 60px;
}
.alert i {
  position: relative;
  font-size: 20px;
  top: 5px;
  margin-top: -5px;
  font-weight: 600;
}

.alert-info {
  background-color: #6ec7e0;
}

.alert-success {
  background-color: #86d9ab;
}

.alert-warning {
  background-color: #fcd27b;
}

.alert-danger {
  background-color: #f7765f;
}

/*           Labels & Progress-bar              */
.label {
  padding: 0.2em 0.6em;
  border-radius: 10px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 0.75em;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 3px;
  line-height: 17px;
}

.label-primary {
  background-color: #51cbce;
}

.label-info {
  background-color: #51bcda;
}

.label-success {
  background-color: #6bd098;
}

.label-warning {
  background-color: #fbc658;
}

.label-danger {
  background-color: #f5593d;
}

.label-default {
  background-color: #722F37;
}

.tooltip {
  font-size: 14px;
  font-weight: 400;
}

.tooltip-inner {
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 1px 13px rgba(0, 0, 0, 0.14), 0 0 0 1px rgba(115, 71, 38, 0.23);
  color: #722F37;
  max-width: 200px;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
}

.tooltip-inner:after {
  content: "";
  display: inline-block;
  position: absolute;
}

.tooltip-inner:before {
  content: "";
  display: inline-block;
  position: absolute;
}

.tooltip.bs-tooltip-left,
.tooltip.bs-tooltip-left.show {
  margin-right: 5px;
}
.tooltip.bs-tooltip-left .arrow,
.tooltip.bs-tooltip-left.show .arrow {
  border-left: 11px solid rgba(0, 0, 0, 0.2);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  right: -4px;
  left: auto;
  margin-left: 0;
  top: 8px !important;
}
.tooltip.bs-tooltip-left .arrow::before,
.tooltip.bs-tooltip-left.show .arrow::before {
  border-left: 11px solid #FFFFFF;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  right: 1px;
  left: auto;
  margin-left: 0;
  top: -11px !important;
}

.tooltip.bs-tooltip-right,
.tooltip.bs-tooltip-right.show {
  margin-left: 6px;
}
.tooltip.bs-tooltip-right .arrow,
.tooltip.bs-tooltip-right.show .arrow {
  border-right: 11px solid rgba(0, 0, 0, 0.2);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  left: -6px;
  right: auto;
  margin-right: 0;
  top: 9px !important;
}
.tooltip.bs-tooltip-right .arrow::before,
.tooltip.bs-tooltip-right.show .arrow::before {
  border-right: 11px solid #FFFFFF;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  left: 1px;
  right: auto;
  margin-right: 0;
  top: -11px;
}

.tooltip.bs-tooltip-top,
.tooltip.bs-tooltip-top.show {
  margin-top: -6px;
}
.tooltip.bs-tooltip-top .arrow,
.tooltip.bs-tooltip-top.show .arrow {
  border-top: 11px solid rgba(0, 0, 0, 0.2);
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  right: auto;
  margin-right: 0;
  left: 40px !important;
  top: 45px;
}
.tooltip.bs-tooltip-top .arrow::before,
.tooltip.bs-tooltip-top.show .arrow::before {
  border-top: 11px solid #FFFFFF;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  left: -11px;
  right: auto;
  top: -12px;
}

.tooltip.bs-tooltip-bottom,
.tooltip.bs-tooltip-bottom.show {
  margin-top: 6px;
}
.tooltip.bs-tooltip-bottom .arrow,
.tooltip.bs-tooltip-bottom.show .arrow {
  border-bottom: 11px solid rgba(0, 0, 0, 0.2);
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  right: auto;
  margin-right: 0;
  top: -4px;
  left: 50px !important;
}
.tooltip.bs-tooltip-bottom .arrow::before,
.tooltip.bs-tooltip-bottom.show .arrow::before {
  border-bottom: 11px solid #FFFFFF;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  left: -11px;
  right: auto;
  top: 1px;
}

.tooltip.show {
  opacity: 1 !important;
}

.popover {
  border-radius: 6px;
  background-color: #FFFCF5;
  color: #66615b;
  font-weight: 400;
  padding: 0;
  z-index: 1031;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.popover-header {
  background-color: #FFFFFF;
  border-bottom: 0 none;
  font-size: 15px;
  font-weight: normal;
  line-height: 22px;
  padding: 15px 15px 0px 15px;
  margin: 0;
  color: #66615b;
  text-align: center;
  border-radius: 6px 6px 0 0;
  margin-bottom: -10px;
}

.popover-body {
  background-color: #FFFFFF;
  padding: 15px;
  text-align: center;
  border-radius: 6px;
}

.popover .arrow {
  border: 0;
}

.popover.top .arrow {
  margin-left: 0;
}

.popover.bottom .arrow:after {
  border-bottom-color: #f7765f;
}

.popover-filter {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  background-color: #000000;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear;
}

.popover-filter.in {
  visibility: visible;
  opacity: 0.2;
  filter: alpha(opacity=20);
  transition-delay: 0s;
}

.popover.left > .arrow::after {
  border-left-color: #f7765f;
  bottom: -20px;
}

.popover.top > .arrow::after {
  border-top-color: #f7765f;
}

.popover.right > .arrow::after {
  border-right-color: #f7765f;
}

.popover.left::before {
  border-left-color: #f7765f;
  bottom: -20px;
}

.popover-filter.in {
  visibility: visible;
  opacity: 0.2;
  filter: alpha(opacity=20);
  transition-delay: 0s;
}

.popover-primary {
  background-color: #6dd3d6;
  color: #FFFFFF;
}
.popover-primary .popover-title {
  background-color: #6dd3d6;
  color: rgba(0, 0, 0, 0.56);
}
.popover-primary.bottom .arrow:after {
  border-bottom-color: #6dd3d6;
}
.popover-primary.left > .arrow::after {
  border-left-color: #6dd3d6;
}
.popover-primary.top > .arrow::after {
  border-top-color: #6dd3d6;
}
.popover-primary.right > .arrow::after {
  border-right-color: #6dd3d6;
}

.popover-info {
  background-color: #6ec7e0;
  color: #FFFFFF;
}
.popover-info .popover-title {
  background-color: #6ec7e0;
  color: rgba(0, 0, 0, 0.56);
}
.popover-info.bottom .arrow:after {
  border-bottom-color: #6ec7e0;
}
.popover-info.left > .arrow::after {
  border-left-color: #6ec7e0;
}
.popover-info.top > .arrow::after {
  border-top-color: #6ec7e0;
}
.popover-info.right > .arrow::after {
  border-right-color: #6ec7e0;
}

.popover-success {
  background-color: #86d9ab;
  color: #FFFFFF;
}
.popover-success .popover-title {
  background-color: #86d9ab;
  color: rgba(0, 0, 0, 0.56);
}
.popover-success.bottom .arrow:after {
  border-bottom-color: #86d9ab;
}
.popover-success.left > .arrow::after {
  border-left-color: #86d9ab;
}
.popover-success.top > .arrow::after {
  border-top-color: #86d9ab;
}
.popover-success.right > .arrow::after {
  border-right-color: #86d9ab;
}

.popover-warning {
  background-color: #fcd27b;
  color: #FFFFFF;
}
.popover-warning .popover-title {
  background-color: #fcd27b;
  color: rgba(0, 0, 0, 0.56);
}
.popover-warning.bottom .arrow:after {
  border-bottom-color: #fcd27b;
}
.popover-warning.left > .arrow::after {
  border-left-color: #fcd27b;
}
.popover-warning.top > .arrow::after {
  border-top-color: #fcd27b;
}
.popover-warning.right > .arrow::after {
  border-right-color: #fcd27b;
}

.popover-danger {
  background-color: #f7765f;
  color: #FFFFFF;
}
.popover-danger .popover-title {
  background-color: #f7765f;
  color: rgba(0, 0, 0, 0.56);
}
.popover-danger.bottom .arrow:after {
  border-bottom-color: #f7765f;
}
.popover-danger.left > .arrow::after {
  border-left-color: #f7765f;
}
.popover-danger.top > .arrow::after {
  border-top-color: #f7765f;
}
.popover-danger.right > .arrow::after {
  border-right-color: #f7765f;
}

.section {
  padding: 70px 0;
  position: relative;
  background-color: #FFFFFF;
}

.section-with-space {
  padding: 60px 0;
}

.section-gray {
  background-color: #EEEEEE;
}

.section-nude {
  background-color: #FFFCF5;
}

.section-gold {
  background-color: #caac90;
  color: #FFFFFF;
}

.section-brown {
  background-color: #A59E94;
  color: #FFFFFF;
}

.section-light-blue {
  background-color: #51cbce;
  color: #FFFFFF;
}

.section-dark-blue {
  background-color: #506367;
  color: #FFFFFF;
}

.section-dark {
  background-color: #0b1011;
}

.section-image,
.section-gold,
.section-dark {
  position: relative;
}
.section-image .title,
.section-image .info-title,
.section-image .card-plain .card-title,
.section-gold .title,
.section-gold .info-title,
.section-gold .card-plain .card-title,
.section-dark .title,
.section-dark .info-title,
.section-dark .card-plain .card-title {
  color: #FFFFFF !important;
}
.section-image .category,
.section-image .description,
.section-image .card-plain .card-description,
.section-image .card-plain .card-category,
.section-gold .category,
.section-gold .description,
.section-gold .card-plain .card-description,
.section-gold .card-plain .card-category,
.section-dark .category,
.section-dark .description,
.section-dark .card-plain .card-description,
.section-dark .card-plain .card-category {
  color: rgba(255, 255, 255, 0.7);
}
.section-image hr,
.section-gold hr,
.section-dark hr {
  border-color: rgba(255, 255, 255, 0.19);
}

.page-header .title,
.page-header .info-title,
.carousel-caption .title,
.carousel-caption .info-title {
  color: #FFFFFF;
}

[class*=features-],
[class*=team-],
[class*=projects-],
[class*=pricing-],
[class*=testimonials-],
[class*=contactus-] {
  padding: 80px 0;
}

.section-image {
  background-position: center center;
  background-size: cover;
}
.section-image:before {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.section-image .container {
  position: relative;
  z-index: 2;
}

.section-login {
  min-height: 700px;
}

.form-check,
.form-check-radio {
  padding: 0;
  margin-bottom: 12px;
  position: relative;
}

.form-check .form-check-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  line-height: 26px;
  margin-bottom: 0;
}
.form-check .form-check-sign::before,
.form-check .form-check-sign::after {
  content: " ";
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  cursor: pointer;
  border-radius: 3px;
  top: 0;
  background-color: #AAA7A4;
  border: 1px solid #E3E3E3;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}
.form-check .form-check-sign::after {
  font-family: "FontAwesome";
  content: "";
  top: 0px;
  text-align: center;
  font-size: 14px;
  opacity: 0;
  color: #EFE;
  border: 0;
  background-color: inherit;
}
.form-check.disabled .form-check-label {
  color: #9A9A9A;
  opacity: 0.5;
  cursor: not-allowed;
}

.form-check input[type=checkbox],
.form-check-radio input[type=radio] {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}

.form-check input[type=checkbox]:checked + .form-check-sign::after {
  opacity: 1;
}

.form-control input[type=checkbox]:disabled + .form-check-sign::before,
.checkbox input[type=checkbox]:disabled + .form-check-sign::after {
  cursor: not-allowed;
}

.form-check .form-check-label input[type=checkbox]:disabled + .form-check-sign,
.form-check-radio input[type=radio]:disabled + .form-check-sign {
  pointer-events: none !important;
}

.form-check-radio .form-check-label {
  padding-left: 2rem;
}
.form-check-radio.disabled .form-check-label {
  color: #9A9A9A;
  opacity: 0.5;
  cursor: not-allowed;
}

.form-check-radio .form-check-sign::before {
  font-family: "FontAwesome";
  content: "";
  font-size: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: absolute;
  opacity: 0.5;
  left: 5px;
  top: -5px;
}

.form-check-label input[type=checkbox]:checked + .form-check-sign:before {
  background-color: #66615B;
}

.form-check-radio input[type=radio] + .form-check-sign:after,
.form-check-radio input[type=radio] {
  opacity: 0;
  display: block;
  content: "";
  -webkit-transition: opacity 1s linear;
  -moz-transition: opacity 1s linear;
  -o-transition: opacity 1s linear;
  -ms-transition: opacity 1s linear;
  transition: opacity 1s linear;
}

.form-check-radio input[type=radio]:checked + .form-check-sign::after {
  font-family: "FontAwesome";
  content: "";
  top: -5px;
  position: absolute;
  left: 5px;
  opacity: 1;
  font-size: 22px;
}

.form-check-radio input[type=radio]:checked + .form-check-sign::after {
  opacity: 1;
}

.form-check-radio input[type=radio]:disabled + .form-check-sign::before,
.form-check-radio input[type=radio]:disabled + .form-check-sign::after {
  color: #9A9A9A;
}

.nav .nav-item .nav-link:hover,
.nav .nav-item .nav-link:focus {
  background-color: transparent;
}

.navbar {
  border: 0;
  font-size: 14px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  padding: 0;
  background: #FFFFFF;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}
.navbar .navbar-brand {
  font-weight: 600;
  margin: 5px 0px;
  padding: 20px 15px;
  font-size: 14px;
  color: #722F37;
  text-transform: uppercase;
}
.navbar .navbar-brand .logo-header {
  content: url("../../img/logo2.png");
}
.navbar .nav-link i {
  font-size: 16px;
  position: relative;
  top: 4px;
  right: 3px;
}
.navbar .nav-link [class^=fa] {
  top: 2px;
}
.navbar .nav-link p {
  margin: 0px 0px;
  color: #9A9A9A !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5em;
  padding: 15px 0;
}
.navbar .nav-link p:hover {
  color: #403D39 !important;
}
.navbar .navbar-nav .nav-item .nav-link {
  line-height: 1.6;
  margin: 15px 0px;
  padding: 10px 15px;
  opacity: 0.8;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: #722F37;
}
.navbar .navbar-nav .nav-item .nav-link.btn {
  margin: 15px 3px;
  padding: 9px;
}
.navbar .navbar-nav .dropdown-menu {
  border-radius: 12px;
  margin-top: 1px;
}
.navbar .navbar-collapse .nav-item .nav-link p {
  display: inline;
}
.navbar .navbar-collapse .nav-item .dropdown-item i {
  margin: 0 10px;
  margin: 0 10px 0px 5px;
  font-size: 18px;
  position: relative;
  top: 3px;
}
.navbar .navbar-collapse.show .navbar-nav .nav-item {
  padding-right: 10px;
}
.navbar .notification-bubble {
  right: 72px;
  padding: 0.2em 0.6em;
  position: absolute;
  top: 15px;
}
.navbar .btn {
  margin: 15px 3px;
  font-size: 14px;
}
.navbar .btn i {
  font-size: 14px;
  line-height: 13px;
}
.navbar .btn-simple {
  font-size: 16px;
}
.navbar .caret {
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
}
.navbar.navbar-transparent {
  padding-top: 25px;
}
.navbar .logo-container {
  margin-top: 5px;
}
.navbar .logo-container .logo {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
  width: 50px;
  float: left;
}
.navbar .logo-container .logo img {
  width: 100%;
}
.navbar .logo-container .brand {
  font-size: 18px;
  color: #FFFFFF;
  line-height: 20px;
  float: left;
  margin-left: 10px;
  margin-top: 5px;
  width: 75px;
  height: 50px;
}

.navbar.fixed-top .nav-link i {
  top: 4px;
}

.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}

.navbar-transparent .navbar-brand, [class*=bg] .navbar-brand {
  color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.navbar-transparent .navbar-brand:focus, .navbar-transparent .navbar-brand:hover, [class*=bg] .navbar-brand:focus, [class*=bg] .navbar-brand:hover {
  background-color: transparent;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #FFFFFF;
}
.navbar-transparent .navbar-brand .logo-header, [class*=bg] .navbar-brand .logo-header {
  content: url("../../img/logo.png");
}
.navbar-transparent .navbar-nav .nav-item .nav-link:not(.btn), [class*=bg] .navbar-nav .nav-item .nav-link:not(.btn) {
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.navbar-transparent .navbar-nav .active .nav-link .active .nav-link:hover,
.navbar-transparent .navbar-nav .active .nav-link:focus,
.navbar-transparent .navbar-nav .nav-item .nav-link:hover,
.navbar-transparent .navbar-nav .nav-item .nav-link:focus, [class*=bg] .navbar-nav .active .nav-link .active .nav-link:hover,
[class*=bg] .navbar-nav .active .nav-link:focus,
[class*=bg] .navbar-nav .nav-item .nav-link:hover,
[class*=bg] .navbar-nav .nav-item .nav-link:focus {
  background-color: transparent;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-transparent .navbar-nav .nav .nav-item a.btn:hover, [class*=bg] .navbar-nav .nav .nav-item a.btn:hover {
  background-color: transparent;
}
.navbar-transparent .navbar-nav .dropdown .nav-link .caret,
.navbar-transparent .navbar-nav .dropdown .nav-link:hover .caret,
.navbar-transparent .navbar-nav .dropdown .nav-link:focus .caret, [class*=bg] .navbar-nav .dropdown .nav-link .caret,
[class*=bg] .navbar-nav .dropdown .nav-link:hover .caret,
[class*=bg] .navbar-nav .dropdown .nav-link:focus .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF;
}
.navbar-transparent .navbar-nav .open .nav-link,
.navbar-transparent .navbar-nav .open .nav-link:hover,
.navbar-transparent .navbar-nav .open .nav-link:focus, [class*=bg] .navbar-nav .open .nav-link,
[class*=bg] .navbar-nav .open .nav-link:hover,
[class*=bg] .navbar-nav .open .nav-link:focus {
  background-color: transparent;
  color: #722F37;
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-transparent .btn-default.btn-fill, [class*=bg] .btn-default.btn-fill {
  color: #9A9A9A;
  background-color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.navbar-transparent .btn-default.btn-fill:hover,
.navbar-transparent .btn-default.btn-fill:focus,
.navbar-transparent .btn-default.btn-fill:active,
.navbar-transparent .btn-default.btn-fill.active,
.navbar-transparent .open .dropdown-toggle.btn-fill.btn-default, [class*=bg] .btn-default.btn-fill:hover,
[class*=bg] .btn-default.btn-fill:focus,
[class*=bg] .btn-default.btn-fill:active,
[class*=bg] .btn-default.btn-fill.active,
[class*=bg] .open .dropdown-toggle.btn-fill.btn-default {
  border-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}

.nav-open .nav .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF;
}

.navbar-default .brand {
  color: #66615b !important;
}
.navbar-default .navbar-nav .nav-item .nav-link:not(.btn) {
  color: #9A9A9A;
}
.navbar-default .navbar-nav .active .nav-link,
.navbar-default .navbar-nav .active .nav-link:not(.btn):hover,
.navbar-default .navbar-nav .active .nav-link:not(.btn):focus,
.navbar-default .navbar-nav .nav-item .nav-link:not(.btn):hover,
.navbar-default .navbar-nav .nav-item .nav-link:not(.btn):focus {
  background-color: transparent;
  border-radius: 3px;
  color: #51bcda;
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-default .navbar-nav .dropdown .nav-link:hover .caret,
.navbar-default .navbar-nav .dropdown .nav-link:focus .caret {
  border-bottom-color: #51bcda;
  border-top-color: #51bcda;
}
.navbar-default .navbar-nav .open .nav-link,
.navbar-default .navbar-nav .open .nav-link:hover,
.navbar-default .navbar-nav .open .nav-link:focus {
  background-color: transparent;
  color: #51bcda;
}
.navbar-default .navbar-nav .navbar-toggle:hover, .navbar-default .navbar-nav .navbar-toggle:focus {
  background-color: transparent;
}
.navbar-default:not(.navbar-transparent) .btn-default:hover {
  color: #51bcda;
  border-color: #51bcda;
}
.navbar-default:not(.navbar-transparent) .btn-neutral, .navbar-default:not(.navbar-transparent) .btn-neutral:hover, .navbar-default:not(.navbar-transparent) .btn-neutral:active {
  color: #9A9A9A;
}

/*      Navbar with icons            */
.navbar-icons.navbar .navbar-brand {
  margin-top: 12px;
  margin-bottom: 12px;
}
.navbar-icons .navbar-nav .nav-item .nav-link {
  text-align: center;
  padding: 6px 15px;
  margin: 6px 3px;
}
.navbar-icons .navbar-nav [class^=pe] {
  font-size: 30px;
  position: relative;
}
.navbar-icons .navbar-nav p {
  margin: 3px 0 0;
}

.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navbar-form .form-control {
  border-radius: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  height: 22px;
  font-size: 14px;
  line-height: 1.5em;
  color: #E3E3E3;
}
.navbar-transparent .navbar-form .form-control, [class*=bg] .navbar-form .form-control {
  color: #FFFFFF;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.navbar-toggle {
  margin-top: 19px;
  margin-bottom: 19px;
  border: 0;
}
.navbar-toggle .icon-bar {
  background-color: #FFFFFF;
}
.navbar-toggle .navbar-collapse,
.navbar-toggle .navbar-form {
  border-color: transparent;
}
.navbar-toggle.navbar-default .navbar-toggle:hover, .navbar-toggle.navbar-default .navbar-toggle:focus {
  background-color: transparent;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #E3E3E3;
}

.red {
  color: #ff0000;
}

.collapse .navbar-text {
  line-height: 55px;
}

.navbar-default .navbar-brand {
  color: #722F37;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #5e5e5e;
}

.navbar-collapse.show .navbar-nav .nav-item {
  padding-right: 100px;
}

.nav-tabs-navigation:last-child {
  border-bottom: 0 none;
}
.nav-tabs-navigation:last-child .nav-stacked {
  border-right: 1px solid #F1EAE0;
  font-size: 16px;
  font-weight: 600;
  padding: 20px 0;
}
.nav-tabs-navigation:last-child .nav-stacked .nav-item .nav-link {
  padding: 7px 25px;
}

.navbar-nav > li > .dropdown-menu,
.dropdown .dropdown-menu {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s;
}

.navbar-toggler {
  outline: none !important;
  cursor: pointer;
}
.navbar .navbar-toggler .navbar-toggler-bar {
  background: #66615b;
}
.navbar[class*=bg-] .navbar-toggler .navbar-toggler-bar, .navbar.navbar-transparent .navbar-toggler .navbar-toggler-bar {
  background: #fff;
}
.navbar-toggler .navbar-toggler-bar {
  display: block;
  position: relative;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  margin: 0 auto;
}

.navbar-toggler .navbar-toggler-bar + .navbar-toggler-bar,
.navbar-toggler .navbar-toggler-icon + .navbar-toggler-icon {
  margin-top: 4px;
}

.navbar-toggler-icon {
  display: block;
  position: relative;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  margin: 0 auto;
  background: gray;
}

.no-transition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

#description-areas .nav-stacked .nav-link.active:before,
#navtabs-row .nav-stacked .nav-link.active:before {
  border-right: 11px solid #F1EAE0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 7px;
}

#description-areas .nav-stacked .nav-link.active:after,
#navtabs-row .nav-stacked .nav-link.active:after {
  border-right: 11px solid #FFFFFF;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: -1px;
  bottom: 7px;
}

#second-tabs {
  margin-left: 20px;
}

.scroll-area {
  max-height: 310px;
  overflow-y: scroll;
  list-style: outside none none;
  padding: 0px;
}

.burger-menu .collapse .navbar-nav a {
  color: #333333;
}

.navbar-transparent {
  background: transparent !important;
  border-bottom: 1px solid transparent;
  box-shadow: none;
}
.navbar-transparent .dropdown-menu .divider {
  background-color: rgba(255, 255, 255, 0.2);
}

.section-navbars #menu-dropdown .navbar .navbar-toggler .navbar-toggler-icon {
  background: #FFFFFF;
}

.navbar-hidden {
  display: none;
}

.img-rounded {
  border-radius: 12px;
  transition: opacity 0.5s ease 0s;
  max-width: 100%;
}

.img-details {
  min-height: 50px;
  padding: 0 4px 0.5em;
}

.img-details img {
  width: 50px;
}

.img-details .author {
  margin-left: 10px;
  margin-top: -21px;
  width: 40px;
}

.img-circle {
  background-color: #FFFFFF;
  margin-bottom: 10px;
  padding: 4px;
  border-radius: 50% !important;
  max-width: 100%;
}

.img-thumbnail {
  border: 0 none;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(164, 158, 147, 0.6);
  margin-bottom: 10px;
}

.img-no-padding {
  padding: 0px;
}

.example-page .img-rounded {
  margin: 50px 0 20px;
}

.img-shadow {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.images-title {
  margin-bottom: 20px;
  height: 50px;
}

.nav-link .profile-photo-small {
  width: 40px;
  height: 30px;
  margin: -10px 0 0 -15px;
}

.profile-picture {
  margin: 0 auto;
}
.profile-picture .fileinput-new img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.profile-picture .fileinput-exists img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #FFFFFF;
  color: #333333;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: 0 none;
  -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
  transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
}
.card:not(.card-plain):hover {
  box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
}
.card.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}
.card .card-footer {
  padding: 15px;
  background: transparent;
  border-top: 0 none;
}
.card .card-footer .social-line .btn:first-child {
  border-radius: 0 0 0 6px;
}
.card .card-footer .social-line .btn:last-child {
  border-radius: 0 0 6px 0;
}
.card .card-block .card-footer {
  padding: 0;
}
.card .card-block .card-description + .card-footer {
  padding-top: 10px;
}
.card.no-transition:hover, .card.card-register:hover, .card.page-carousel:hover {
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  transform: none;
  -webkit-transform: none;
  -ms-transform: none;
  -moz-transform: none;
}

.section-dark .card-profile.card-plain .card-title {
  color: #FFFFFF !important;
}
.section-dark .card-profile.card-plain .card-description {
  color: rgba(255, 255, 255, 0.7);
}

.card-profile {
  margin-top: 30px;
  text-align: center;
}
.card-profile .card-cover {
  height: 130px;
  background-position: center center;
  background-size: cover;
  border-radius: 12px 12px 0 0;
}
.card-profile .card-block .card-title {
  margin-top: 5px !important;
}
.card-profile .card-block .card-category {
  margin-bottom: 5px;
  margin-top: 5px;
}
.card-profile .card-avatar {
  max-width: 120px;
  max-height: 120px;
  margin: -60px auto 0;
  border-radius: 50%;
  overflow: hidden;
}
.card-profile .card-avatar img {
  max-width: 100%;
  height: auto;
}
.card-profile .card-avatar.border-white {
  border: 4px solid #FFFFFF;
}
.card-profile .card-avatar.border-gray {
  border: 4px solid #ccc;
}

.section .card-profile {
  margin-top: 100px;
}

.card-register {
  background-color: #FF8F5E;
  border-radius: 8px;
  color: #fff;
  max-width: 350px;
  margin: 120px 0 70px;
  min-height: 400px;
  padding: 30px;
  z-index: 1;
}
.card-register .social-line .btn {
  margin-top: 0;
}

.section-image .card-register {
  margin-top: 0;
}

.card-register label {
  margin-top: 15px;
}

.card-register .title {
  color: #B33C12;
  text-align: center;
}

.card-register .btn {
  margin-top: 30px;
}

.card-register .forgot {
  text-align: center;
}

.footer {
  background-attachment: fixed;
  position: relative;
  line-height: 20px;
}

.footer nav > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.footer nav > ul > li {
  display: inline-block;
  padding: 10px 15px;
  margin: 15px 3px;
  line-height: 20px;
  text-align: center;
}

.footer nav > ul a:not(.btn) {
  color: #777777;
  display: block;
  margin-bottom: 3px;
  line-height: 1.6;
  opacity: 0.8;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.footer nav > ul a:not(.btn):hover,
.footer nav > ul a:not(.btn):focus {
  color: #777777;
  opacity: 1;
}

.footer .copyright {
  color: #777777;
  padding: 10px 0px;
  font-size: 14px;
  margin: 15px 3px;
  line-height: 20px;
  text-align: center;
}

.footer .heart {
  color: #EB5E28;
}

.footer {
  background-color: #FFFFFF;
  line-height: 36px;
}
.footer.footer-black h4 {
  color: #FFFFFF;
}
.footer .links {
  display: inline-block;
}
.footer .links ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 600;
}
.footer .links ul > li {
  display: inline-block;
  padding-right: 20px;
}
.footer .links ul > li:last-child {
  padding-right: 0px;
}
.footer .links ul a:not(.btn) {
  color: #66615b;
  display: block;
  font-size: 0.9em;
  margin-bottom: 3px;
}
.footer .links ul a:not(.btn):hover, .footer .links ul a:not(.btn):focus {
  color: #403D39;
}
.footer .links ul.uppercase-links {
  text-transform: uppercase;
}
.footer .links ul.stacked-links {
  margin-top: 10px;
}
.footer .links ul.stacked-links > li {
  display: block;
  line-height: 26px;
}
.footer .links ul.stacked-links h4 {
  margin-top: 0px;
}
.footer hr {
  border-color: #DDDDDD;
  border-width: 1px 0 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
.footer .copyright {
  color: #A49E9E;
  font-size: 0.9em;
}
.footer .copyright ul > li {
  padding-right: 0px;
}
.footer .title {
  color: #403D39;
}

.footer-black,
.footer-transparent,
.subscribe-line-transparent {
  background-color: #252422;
  color: #DDDDDD;
}
.footer-black .links ul a:not(.btn),
.footer-transparent .links ul a:not(.btn),
.subscribe-line-transparent .links ul a:not(.btn) {
  color: #A49E9E;
}
.footer-black .links ul a:not(.btn):hover, .footer-black .links ul a:not(.btn):focus,
.footer-transparent .links ul a:not(.btn):hover,
.subscribe-line-transparent .links ul a:not(.btn):hover,
.footer-transparent .links ul a:not(.btn):focus,
.subscribe-line-transparent .links ul a:not(.btn):focus {
  color: #F1EAE0;
}
.footer-black .copyright,
.footer-transparent .copyright,
.subscribe-line-transparent .copyright {
  color: #66615b;
}
.footer-black .copyright ul > li a:not(.btn),
.footer-transparent .copyright ul > li a:not(.btn),
.subscribe-line-transparent .copyright ul > li a:not(.btn) {
  color: #66615b;
}
.footer-black hr,
.footer-transparent hr,
.subscribe-line-transparent hr {
  border-color: #66615b;
}

.footer-transparent, .subscribe-line-transparent {
  background-size: cover;
  position: relative;
}
.footer-transparent .container, .subscribe-line-transparent .container {
  z-index: 2;
  position: relative;
}
.footer-transparent hr, .subscribe-line-transparent hr {
  border-color: #A49E9E;
}
.footer-transparent .copyright, .subscribe-line-transparent .copyright {
  color: #A49E9E;
}
.footer-transparent .copyright ul > li a:not(.btn), .subscribe-line-transparent .copyright ul > li a:not(.btn) {
  color: #A49E9E;
}
.footer-transparent::after, .subscribe-line-transparent::after {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(17, 17, 17, 0.5);
  display: block;
  content: "";
  z-index: 1;
}

.footer-gray {
  background-color: #F3F2EE;
}

.footer-big {
  padding: 30px 0;
}
.footer-big hr {
  margin-top: 20px;
}
.footer-big .copyright {
  margin: 10px 0px 20px;
}
.footer-big .form-group {
  margin-top: 15px;
}

.subscribe-line {
  background-color: #FFFCF5;
  padding: 35px 0;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  margin-top: 0;
}
.subscribe-line .form-group {
  margin: 0;
}
.subscribe-line .form-control {
  height: auto;
  font-size: 1.825em;
  border: 0;
  padding: 0;
  font-weight: 300;
  line-height: 54px;
  background-color: transparent;
}
.subscribe-line .btn:not(.btn-lg) {
  margin-top: 7px;
}

.subscribe-line-black {
  background-color: #252422;
}
.subscribe-line-black .form-control {
  color: #FFFFFF;
}

.subscribe-line-transparent .form-control {
  color: #FFFFFF;
}

.social-line-black {
  background-color: #252422;
  color: #FFFFFF;
}

.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: 30px;
  border: 0;
  position: relative;
  text-align: left;
  overflow: hidden;
  margin-bottom: 5px;
  line-height: 8px;
  margin-right: 3px;
  width: 61px !important;
  height: 26px;
  outline: none;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.bootstrap-switch .bootstrap-switch-container {
  display: inline-flex;
  top: 0;
  height: 26px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100px !important;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block !important;
  height: 100%;
  color: #fff;
  padding: 6px 12px;
  font-size: 11px;
  text-indent: -5px;
  line-height: 15px;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
  float: left;
  width: 50% !important;
  background-color: #66615B;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background: #51cbce;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background: #447DF7;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background: #7AC29A;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  background: #FFA534;
  color: #fff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: #FB404B;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #fff;
  background: #cfcfca;
}

.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  color: #333333;
  background: #ffffff;
  width: 22px !important;
  height: 22px;
  margin: 2px -11px;
  border-radius: 12px;
  position: relative;
  float: left;
  padding: 0;
  background-color: #FFFFFF;
  box-shadow: 0 1px 1px #FFFFFF inset, 0 1px 1px rgba(0, 0, 0, 0.25);
}

.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.bootstrap-switch .bootstrap-switch-handle-off {
  text-indent: 6px;
}

.bootstrap-switch input[type=radio],
.bootstrap-switch input[type=checkbox] {
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.bootstrap-switch input[type=radio].form-control,
.bootstrap-switch input[type=checkbox].form-control {
  height: auto;
}

.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
  padding: 6px 16px;
  font-size: 18px;
  line-height: 1.33;
}

.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.5s;
  transition: margin-left 0.5s;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
  margin-left: -2px !important;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
  margin-left: -37px !important;
}

.bootstrap-switch.bootstrap-switch-on:hover .bootstrap-switch-label {
  width: 26px !important;
  margin: 2px -15px;
}

.bootstrap-switch.bootstrap-switch-off:hover .bootstrap-switch-label {
  width: 26px !important;
  margin: 2px -15px -13px -11px;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-handle-off {
  background-color: #66615B;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-handle-on {
  background-color: #cfcfca;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-danger ~ .bootstrap-switch-default {
  background-color: #FB404B;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-warning ~ .bootstrap-switch-default {
  background-color: #FFA534;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-success ~ .bootstrap-switch-default {
  background-color: #7AC29A;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-primary ~ .bootstrap-switch-default {
  background-color: #51cbce;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-info ~ .bootstrap-switch-default {
  background-color: #447DF7;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-danger,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-primary,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-info,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-warning,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-success {
  background-color: #cfcfca;
}

.dropdown .dropdown-menu {
  background-color: #FFFFFF;
  border: 0 none;
  border-radius: 12px;
  display: block;
  margin-top: 10px;
  padding: 0px;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(17, 16, 15, 0.1);
  box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(17, 16, 15, 0.1);
}
.dropdown .dropdown-menu .divider {
  background-color: #F1EAE0;
  margin: 0px;
}
.dropdown .dropdown-menu .dropdown-header {
  color: #9A9A9A;
  font-size: 14px;
  padding: 10px 15px;
}
.dropdown .dropdown-menu .no-notification {
  color: #9A9A9A;
  font-size: 1.2em;
  padding: 30px 30px;
  text-align: center;
}
.dropdown .dropdown-menu .dropdown-item {
  color: #66615b;
  font-size: 14px;
  padding: 10px 45px 10px 15px;
  clear: both;
  white-space: nowrap;
  width: 100%;
  display: block;
}
.dropdown .dropdown-menu .dropdown-item:hover {
  color: #FFFFFF;
}
.dropdown .dropdown-menu .dropdown-item img {
  margin-top: -3px;
}
.dropdown .dropdown-menu .dropdown-item a:focus {
  outline: 0 !important;
}
.btn-group.select .dropdown .dropdown-menu {
  min-width: 100%;
}
.dropdown .dropdown-menu .dropdown-item:first-child a,
.dropdown .dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.dropdown .dropdown-menu .dropdown-item:last-child a,
.dropdown .dropdown-menu .dropdown-item:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.select .dropdown .dropdown-menu .dropdown-item:first-child a {
  border-radius: 0;
  border-bottom: 0 none;
}
.dropdown .dropdown-menu .dropdown-item a:hover,
.dropdown .dropdown-menu .dropdown-item a:focus {
  color: #FFFFFF;
  opacity: 1;
  text-decoration: none;
}
.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:focus {
  background-color: #722F37;
}
.dropdown .dropdown-menu.dropdown-primary .dropdown-item:hover, .dropdown .dropdown-menu.dropdown-primary .dropdown-item:focus {
  background-color: #6dd3d6;
}
.dropdown .dropdown-menu.dropdown-info .dropdown-item:hover, .dropdown .dropdown-menu.dropdown-info .dropdown-item:focus {
  background-color: #6ec7e0;
}
.dropdown .dropdown-menu.dropdown-success .dropdown-item:hover, .dropdown .dropdown-menu.dropdown-success .dropdown-item:focus {
  background-color: #86d9ab;
}
.dropdown .dropdown-menu.dropdown-warning .dropdown-item:hover, .dropdown .dropdown-menu.dropdown-warning .dropdown-item:focus {
  background-color: #fcd27b;
}
.dropdown .dropdown-menu.dropdown-danger .dropdown-item:hover, .dropdown .dropdown-menu.dropdown-danger .dropdown-item:focus {
  background-color: #f7765f;
}
.dropdown .dropdown-divider {
  margin: 0 !important;
}
.dropdown.show .dropdown-menu {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
}

.navbar-nav.mr-auto .dropdown-menu:before,
.navbar-nav.mr-auto .dropdown-menu:after,
.navbar-nav.ml-auto .dropdown-menu:before,
.navbar-nav.ml-auto .dropdown-menu:after {
  left: 12px !important;
  right: auto;
}

.btn-group.select.open {
  overflow: visible;
}

.dropdown-menu-right {
  right: -2px !important;
  left: auto !important;
}

@media (min-width: 768px) {
  .navbar-form {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .navbar-search-form {
    display: none;
  }

  .navbar-nav .dropdown-item .dropdown-menu,
.dropdown .dropdown-menu,
.dropdown-btn .dropdown-menu {
    transform: translate3d(0px, -40px, 0px);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s;
  }

  .navbar-nav .dropdown-item.show .dropdown-menu,
.dropdown.show .dropdown-menu,
.dropdown-btn.show .dropdown-menu {
    transform: translate3d(0px, 15px, 0px);
    visibility: visible !important;
  }

  .bootstrap-select .dropdown-menu {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
  }

  .bootstrap-datetimepicker-widget {
    visibility: visible !important;
    opacity: 1;
    filter: alpha(opacity=100);
  }

  .dropup.show .dropdown-menu {
    -webkit-transform: translate3d(0, -10px, 0);
    -moz-transform: translate3d(0, -10px, 0);
    -o-transform: translate3d(0, -10px, 0);
    -ms-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    opacity: 1;
    visibility: visible;
  }

  .dropup .dropdown-menu {
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s;
    -webkit-transform: translate3d(0, 30px, 0);
    -moz-transform: translate3d(0, 30px, 0);
    -o-transform: translate3d(0, 30px, 0);
    -ms-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
    opacity: 0;
    visibility: hidden;
    display: block;
  }

  .bootstrap-select .show .dropdown-menu {
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s;
    transform: translate3d(0px, 0px, 0px);
  }

  .navbar-nav li .dropdown-menu:before,
#dropdown-row .dropdown .dropdown-menu:before,
.card.card-just-text .dropdown .dropdown-menu:before,
.card-just-text .dropdown .dropdown-menu:before,
.dropdown-btn .dropdown-menu:before {
    border-bottom: 11px solid #F1EAE0;
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -11px;
  }

  .dropdown .dropdown-menu-right.dropdown-menu:before {
    right: 12px;
    left: auto;
  }

  .navbar-nav li .dropdown-menu:after,
#dropdown-row .dropdown .dropdown-menu:after,
.card.card-just-text .dropdown .dropdown-menu:after,
.card-just-text .dropdown .dropdown-menu:after,
.dropdown-btn .dropdown-menu:after {
    border-bottom: 11px solid #FFFFFF;
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -10px;
  }

  .dropdown .dropdown-menu-right.dropdown-menu:after {
    right: 12px;
    left: auto;
  }

  #dropdown-row .dropdown .dropdown-menu {
    left: 15px;
  }

  .navbar-nav.navbar-right li .dropdown-menu:before,
.navbar-nav.navbar-right li .dropdown-menu:after {
    left: auto;
    right: 12px;
  }

  .footer:not(.footer-big) nav ul li:first-child {
    margin-left: 0;
  }

  body > .navbar-collapse.collapse {
    display: none !important;
  }
}
#navbar .dropdown-menu .dropdown-item {
  padding: 3px 1.5rem !important;
}

.dropdown-sharing li {
  color: #66615b;
  font-size: 14px;
}
.dropdown-sharing li .social-line {
  line-height: 28px;
  padding: 10px 20px 5px 20px;
}
.dropdown-sharing li .social-line [class*=icon-] {
  font-size: 20px;
}
.dropdown-sharing li:hover .social-line,
.dropdown-sharing li:hover a,
.dropdown-sharing li:hover .action-line,
.dropdown-sharing li:focus .social-line,
.dropdown-sharing li:focus a,
.dropdown-sharing li:focus .action-line {
  background-color: #FFFCF5;
  color: #66615b;
  opacity: 1;
  text-decoration: none;
}

.show .dropdown-sharing {
  margin-bottom: 1px;
}
.show .dropdown-sharing li:last-child {
  padding: 10px 15px;
}

.show .dropdown-actions {
  margin-bottom: 1px;
}

.dropdown-actions li {
  margin: -15px 35px;
}
.dropdown-actions li .action-line {
  padding: 5px 10px;
  line-height: 24px;
  font-weight: bold;
}
.dropdown-actions li .action-line [class*=icon-] {
  font-size: 24px;
}
.dropdown-actions li .action-line .col-sm-9 {
  line-height: 34px;
}
.dropdown-actions li .link-danger {
  color: #f5593d;
}
.dropdown-actions li .link-danger:hover, .dropdown-actions li .link-danger:active, .dropdown-actions li .link-danger:focus {
  color: #f5593d;
}
.dropdown-actions li:hover a,
.dropdown-actions li:focus a {
  color: #66615b;
  opacity: 1;
  text-decoration: none;
}
.dropdown-actions .action-line .icon-simple {
  margin-left: -15px;
}

.dropup .dropdown-menu:before {
  border-top: 11px solid #DCD9D1;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: 12px;
  bottom: -12px;
}

.dropup .dropdown-menu:after {
  border-top: 11px solid #FFFCF5;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: 12px;
  bottom: -11px;
}

.dropup .dropdown-toggle:after,
.dropdown .dropdown-toggle:after {
  margin-left: 5px;
}

.dropdown-notification .dropdown-notification-list li {
  border-bottom: 1px solid #F1EAE0;
  color: #66615b;
  font-size: 16px;
  padding: 10px 5px;
  width: 330px;
}
.dropdown-notification .dropdown-notification-list li a {
  color: #66615b;
  white-space: normal;
}
.dropdown-notification .dropdown-notification-list li a .notification-text {
  padding-left: 40px;
  position: relative;
}
.dropdown-notification .dropdown-notification-list li a .notification-text .label {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 7px;
}
.dropdown-notification .dropdown-notification-list li a .notification-text .message {
  font-size: 0.9em;
  line-height: 0.7;
  margin-left: 10px;
}
.dropdown-notification .dropdown-notification-list li a .notification-text .time {
  color: #9A9A9A;
  font-size: 0.7em;
  margin-left: 10px;
}
.dropdown-notification .dropdown-notification-list li a .read-notification {
  font-size: 12px;
  opacity: 0;
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -12px;
}
.dropdown-notification .dropdown-notification-list li:hover {
  background-color: #F0EFEB;
  color: #66615b;
  opacity: 1;
  text-decoration: none;
}
.dropdown-notification .dropdown-notification-list li:hover .read-notification {
  opacity: 1 !important;
}
.dropdown-notification .dropdown-footer {
  background-color: #E8E7E3;
  border-radius: 0 0 8px 8px;
}
.dropdown-notification .dropdown-footer .dropdown-footer-menu {
  list-style: outside none none;
  padding: 0px 5px;
}
.dropdown-notification .dropdown-footer .dropdown-footer-menu li {
  display: inline-block;
  text-align: left;
  padding: 0 10px;
}
.dropdown-notification .dropdown-footer .dropdown-footer-menu li a {
  color: #9C9B99;
  font-size: 0.9em;
  line-height: 35px;
}

.section-nucleo-icons {
  padding: 100px 0;
}
.section-nucleo-icons .icons-container {
  position: relative;
  max-width: 450px;
  height: 300px;
  max-height: 300px;
  margin: 0 auto;
}
.section-nucleo-icons .icons-container i {
  font-size: 34px;
  position: absolute;
  top: 0;
  left: 0;
}
.section-nucleo-icons .icons-container i:nth-child(1) {
  top: 5%;
  left: 7%;
}
.section-nucleo-icons .icons-container i:nth-child(2) {
  top: 28%;
  left: 24%;
}
.section-nucleo-icons .icons-container i:nth-child(3) {
  top: 40%;
}
.section-nucleo-icons .icons-container i:nth-child(4) {
  top: 18%;
  left: 62%;
}
.section-nucleo-icons .icons-container i:nth-child(5) {
  top: 74%;
  left: 3%;
}
.section-nucleo-icons .icons-container i:nth-child(6) {
  top: 36%;
  left: 44%;
  font-size: 65px;
  color: #f5593d;
  padding: 1px;
}
.section-nucleo-icons .icons-container i:nth-child(7) {
  top: 59%;
  left: 26%;
}
.section-nucleo-icons .icons-container i:nth-child(8) {
  top: 60%;
  left: 69%;
}
.section-nucleo-icons .icons-container i:nth-child(9) {
  top: 72%;
  left: 47%;
}
.section-nucleo-icons .icons-container i:nth-child(10) {
  top: 88%;
  left: 27%;
}
.section-nucleo-icons .icons-container i:nth-child(11) {
  top: 31%;
  left: 80%;
}
.section-nucleo-icons .icons-container i:nth-child(12) {
  top: 88%;
  left: 68%;
}
.section-nucleo-icons .icons-container i:nth-child(13) {
  top: 5%;
  left: 81%;
}
.section-nucleo-icons .icons-container i:nth-child(14) {
  top: 58%;
  left: 90%;
}
.section-nucleo-icons .icons-container i:nth-child(15) {
  top: 6%;
  left: 40%;
}

.section-dark .icons-container {
  color: #FFFFFF;
}

.info .icon {
  margin-top: 0;
  font-size: 3.4em;
}

.icon-primary {
  color: #51cbce;
}

.icon-info {
  color: #51bcda;
}

.icon-success {
  color: #6bd098;
}

.icon-warning {
  color: #fbc658;
}

.icon-danger {
  color: #f5593d;
}

.icon-neutral {
  color: #FFFFFF;
}

/*             Navigation menu                */
/*             Navigation Tabs                 */
.nav-tabs-navigation {
  text-align: center;
  border-bottom: 1px solid #F1EAE0;
}
.nav-tabs-navigation .nav > .nav-item > .nav-link {
  padding-bottom: 20px;
}

.nav-tabs-wrapper {
  display: inline-block;
  margin-bottom: -6px;
  margin-left: 1.25%;
  margin-right: 1.25%;
  position: relative;
  width: 100%;
}

.nav-tabs {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #F1EAE0;
  margin-bottom: 30px;
}
.nav-tabs .nav-item .nav-link {
  border: 0 none;
  color: #A49E93;
  background-color: transparent;
}
.nav-tabs .nav-item .nav-link:hover {
  color: #66615b;
}
.nav-tabs .nav-item .nav-link.active {
  color: #66615b;
}
.nav-tabs .nav-item {
  color: #66615b;
  position: relative;
}
.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item .nav-link.active:hover,
.nav-tabs .nav-item .nav-link.active:focus {
  background-color: transparent;
  border: 0 none;
}
.nav-tabs .nav-item .nav-link.active:after,
.nav-tabs .nav-item .nav-link.active:hover:after,
.nav-tabs .nav-item .nav-link.active:focus:after {
  border-bottom: 11px solid #FFFFFF;
  border-left: 11px solid rgba(0, 0, 0, 0);
  border-right: 11px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  position: absolute;
  right: 40%;
  bottom: 0px;
}
.nav-tabs .nav-item .nav-link.active:before,
.nav-tabs .nav-item .nav-link.active:hover:before,
.nav-tabs .nav-item .nav-link.active:focus:before {
  border-bottom: 11px solid #F1EAE0;
  border-left: 11px solid rgba(0, 0, 0, 0);
  border-right: 11px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  position: absolute;
  right: 40%;
  bottom: 1px;
}
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
}
.nav-tabs .dropdown-menu {
  margin-top: -6px;
  margin-left: -46px;
  border-radius: 8px;
}
.nav-tabs .dropdown-menu .dropdown-item:hover,
.nav-tabs .dropdown-menu .dropdown-item.active {
  color: #FFFFFF;
  background-color: #68B3C8;
}
.nav-tabs .dropdown-menu :before {
  border-bottom: 11px solid #F1EAE0;
  border-left: 11px solid rgba(0, 0, 0, 0);
  border-right: 11px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  position: absolute;
  right: 12px;
  top: -11px;
}
.nav-tabs .dropdown-menu :after {
  border-bottom: 11px solid #FFFCF5;
  border-left: 11px solid rgba(0, 0, 0, 0);
  border-right: 11px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  position: absolute;
  right: 12px;
  top: -10px;
}

.profile-content .tab-content .tab-pane {
  min-height: 200px;
}
.profile-content .tab-content #tweets, .profile-content .tab-content #connections, .profile-content .tab-content #media {
  height: 100%;
}

/*             Navigation Pills               */
.nav-pills .nav-item + .nav-link {
  margin-left: 0;
}
.nav-pills .nav-item .nav-link {
  border: 1px solid #722F37;
  border-radius: 0;
  color: #722F37;
  font-weight: 600;
  margin-left: -1px;
  padding: 10px 25px;
}
.nav-pills .nav-item.active .nav-link,
.nav-pills .nav-item.active .nav-link:hover,
.nav-pills .nav-item.active .nav-link:focus {
  background-color: #722F37;
  color: #FFFFFF;
}
.nav-pills .nav-item:first-child .nav-link {
  border-radius: 30px 0 0 30px !important;
  margin: 0;
}
.nav-pills .nav-item:last-child .nav-link {
  border-radius: 0 30px 30px 0 !important;
}
.nav-pills .nav-item .nav-link.active {
  background-color: #722F37;
  color: #FFFFFF;
}

.nav-pills-primary .nav-item .nav-link {
  border: 1px solid #51cbce !important;
  color: #51cbce !important;
}
.nav-pills-primary .nav-item .nav-link.active {
  border: 1px solid #51cbce !important;
  color: #FFFFFF !important;
}

.nav-pills-danger .nav-item .nav-link {
  border: 1px solid #f5593d !important;
  color: #f5593d !important;
}
.nav-pills-danger .nav-item .nav-link.active {
  border: 1px solid #f5593d !important;
  color: #FFFFFF !important;
}

.nav-pills-info .nav-item .nav-link {
  border: 1px solid #51bcda !important;
  color: #51bcda !important;
}
.nav-pills-info .nav-item .nav-link.active {
  border: 1px solid #51bcda !important;
  color: #FFFFFF !important;
}

.nav-pills-success .nav-item .nav-link {
  border: 1px solid #6bd098 !important;
  color: #6bd098 !important;
}
.nav-pills-success .nav-item .nav-link.active {
  border: 1px solid #6bd098 !important;
  color: #FFFFFF !important;
}

.nav-pills-warning .nav-item .nav-link {
  border: 1px solid #fbc658 !important;
  color: #fbc658 !important;
}
.nav-pills-warning .nav-item .nav-link.active {
  border: 1px solid #fbc658 !important;
  color: #FFFFFF !important;
}

.pagination > .page-item > .page-link,
.pagination > .page-item > span,
.pagination > .page-item:first-child > .page-link,
.pagination > .page-item:first-child > span,
.pagination > .page-item:last-child > .page-link,
.pagination > .page-item:last-child > span {
  background-color: transparent;
  border: 2px solid #f5593d;
  border-radius: 20px;
  color: #f5593d;
  height: 36px;
  margin: 0 2px;
  min-width: 36px;
  font-weight: 600;
}

.nav-pills-danger > .page-item > .page-link,
.pagination-danger > .page-item > .page-link,
.pagination-danger > .page-item > span,
.pagination-danger > .page-item:first-child > .page-link,
.pagination-danger > .page-item:first-child > span,
.pagination-danger > .page-item:last-child > .page-link,
.pagination-danger > .page-item:last-child > span {
  border: 2px solid #f5593d;
  color: #f5593d;
}

.nav-pills-danger > .page-item.active > .page-link,
.nav-pills-danger > .page-item.active > .page-link:hover,
.nav-pills-danger > .page-item.active > .page-link:focus,
.pagination-danger > .page-item > .page-link:hover,
.pagination-danger > .page-item > .page-link:focus,
.pagination-danger > .page-item > .page-link:active,
.pagination-danger > .page-item.active > .page-link,
.pagination-danger > .page-item.active > span,
.pagination-danger > .page-item.active > .page-link:hover,
.pagination-danger > .page-item.active > span:hover,
.pagination-danger > .page-item.active > .page-link:focus,
.pagination-danger > .page-item.active > span:focus {
  background-color: #f5593d !important;
  border-color: #f5593d !important;
  color: #FFFFFF;
}

.nav-text, .nav-icons {
  margin: 0 0 10px 0;
}
.nav-text > li > a, .nav-icons > li > a {
  display: block;
  padding: 0px 18px;
  color: #9A9A9A;
  text-align: center;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.nav-text > li > a:hover, .nav-text > li > a:focus, .nav-icons > li > a:hover, .nav-icons > li > a:focus {
  background-color: transparent;
  opacity: 1;
  filter: alpha(opacity=100);
}
.nav-text > li:first-child a, .nav-icons > li:first-child a {
  padding-left: 0;
}
.nav-text > li.active a, .nav-icons > li.active a {
  color: #51bcda;
}

.nav-icons > li {
  display: inline-block;
}
.nav-icons > li > a {
  padding: 0 10px;
  margin-bottom: 10px;
}
.nav-icons > li > a i {
  font-size: 1.6em;
  margin-bottom: 10px;
  width: 1.6em;
}

.nav-icons.nav-stacked > li {
  display: block;
}
.nav-icons.nav-stacked > li > a {
  margin-bottom: 20px;
}

.nav-blue > li.active a {
  color: #51cbce;
}

.nav-azure > li.active a {
  color: #51bcda;
}

.nav-green > li.active a {
  color: #6bd098;
}

.nav-orange > li.active a {
  color: #fbc658;
}

.nav-red > li.active a {
  color: #f5593d;
}

.nav-text {
  margin: 0 0 10px 0;
}
.nav-text > li > a {
  font-size: 0.9em;
  text-transform: uppercase;
  padding: 3px 0;
  text-align: left;
  font-weight: 500;
}
.nav-text > li:first-child > a {
  padding-top: 0;
}
.nav-text h4 {
  margin-top: 0;
}

.nav-text:not(.nav-stacked) > li {
  display: inline-block;
}
.nav-text:not(.nav-stacked) > li > a {
  margin-right: 15px;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.nav-pills-default .nav-item.show .nav-link,
.nav-pills-default .nav-link.active {
  background-color: #722F37 !important;
}

.nav-pills-primary .nav-item.show .nav-link,
.nav-pills-primary .nav-link.active {
  background-color: #51cbce !important;
}

.nav-pills-info .nav-item.show .nav-link,
.nav-pills-info .nav-link.active {
  background-color: #51bcda !important;
}

.nav-pills-warning .nav-item.show .nav-link,
.nav-pills-warning .nav-link.active {
  background-color: #fbc658 !important;
}

.nav-pills-success .nav-item.show .nav-link,
.nav-pills-success .nav-link.active {
  background-color: #6bd098 !important;
}

.nav-pills-danger .nav-item.show .nav-link,
.nav-pills-danger .nav-link.active {
  background-color: #f5593d !important;
}

.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > a:active,
.pagination > li.active > a,
.pagination > li.active > span,
.pagination > li.active > a:hover,
.pagination > li.active > span:hover,
.pagination > li.active > a:focus,
.pagination > li.active > span:focus {
  background-color: #f5593d;
  border-color: #f5593d;
  color: #FFFFFF;
}

.page-item.active .page-link {
  background-color: #f5593d;
  color: white;
  border-color: #f5593d;
}

.nav-pills .nav-link {
  border-radius: 0;
}

.pagination {
  display: inline-block;
}
.pagination .page-item {
  display: inline-block;
}

.panel {
  border: 0;
  border-bottom: 1px solid #DDDDDD;
  box-shadow: none;
}

.panel-default > .panel-heading {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.panel-group .panel {
  border-radius: 0;
}

.panel-title {
  font-size: 1.35em;
}
.panel-title a {
  display: block;
  padding: 0.75rem;
}
.panel-title i {
  float: right;
  padding-top: 5px;
}

.panel-title a:hover,
.panel-title a:focus {
  text-decoration: none;
}

.gsdk-collapse {
  display: block;
  height: 0px;
  visibility: visible;
  overflow: hidden;
}

#accordion .panel-title a:hover,
#accordion .panel-title a:focus {
  color: #f5593d;
}
#accordion .card-header {
  background-color: #FFFFFF;
}

.card-collapse {
  padding: 0 !important;
}

.panel-title a[aria-expanded=true] i {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: #f5593d;
  border-radius: 50%;
  opacity: 1;
  text-shadow: none;
  font-size: 12px;
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  opacity: 1;
  background-color: #f33816;
}

.carousel-control-prev-icon {
  background-image: none !important;
  margin-top: -5px;
}
.carousel-control-prev-icon:before {
  color: #FFFFFF;
  font-size: 26px;
  margin: 2px;
  padding-right: 3px;
  font: normal normal normal 23px/1 FontAwesome !important;
  content: "" !important;
}

.carousel-control-next-icon {
  background-image: none !important;
  margin-top: -5px;
}
.carousel-control-next-icon:before {
  color: #FFFFFF;
  font-size: 26px;
  margin: 2px;
  padding-left: 2px;
  font: normal normal normal 23px/1 FontAwesome !important;
  content: "" !important;
}

.carousel-control-prev {
  height: 30px;
  top: 48%;
  width: 30px;
  left: 20px;
  opacity: 0;
}

.carousel-control-next {
  height: 30px;
  right: 20px;
  top: 48%;
  width: 30px;
  opacity: 0;
}

.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .fa, .carousel-control .fa {
  display: inline-block;
  z-index: 5;
}

.carousel-control-prev .fa {
  font-size: 26px;
  margin: 2px;
  padding-right: 3px;
}

.carousel-control-next .fa {
  font-size: 26px;
  margin: 2px;
  padding-left: 3px;
}

.carousel-control-prev, .carousel-control-next {
  background-image: none;
}

.page-carousel {
  border-radius: 12px !important;
  border: none !important;
}

.carousel-inner .carousel-item img {
  border-radius: 12px;
  box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
  height: 400px;
  width: 100%;
}

.carousel-indicators {
  bottom: 0px;
}

.carousel-indicators li {
  background-color: #FFFFFF;
  border: 0 none;
}

.carousel-indicators .active {
  background-color: #f5593d;
}

.page-carousel:hover .carousel-control-prev,
.page-carousel:hover .carousel-control-next {
  opacity: 1;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

ngb-carousel:focus {
  outline: none !important;
}

.modal-header {
  border-bottom: 1px solid #DDDDDD;
  padding: 20px;
  text-align: center;
  display: block !important;
}
.modal-header.no-border-header {
  border-bottom: 0 none !important;
}
.modal-header.no-border-header .modal-title {
  margin-top: 20px;
}
.modal-header .modal-title {
  color: #333333;
}
.modal-header button.close {
  margin-top: -45px;
}
.modal-header button.close:focus {
  outline: none;
}

.modal-content {
  border: 0 none;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
}
.modal-content .modal-header h6 {
  margin-top: 10px;
}

.modal-dialog {
  padding-top: 60px;
}

.modal-body {
  padding: 20px 50px;
  color: #000;
}

.modal-footer {
  border-top: 1px solid #DDDDDD;
  padding: 0px;
}
.modal-footer.no-border-footer {
  border-top: 0 none;
}

.modal-footer .left-side, .modal-footer .right-side {
  display: inline-block;
  text-align: center;
  width: 48%;
}

.modal-footer .btn-link {
  padding: 20px;
  width: 100%;
}

.modal-footer .divider {
  background-color: #DDDDDD;
  display: inline-block;
  float: inherit;
  height: 63px;
  margin: 0px -3px;
  width: 1px;
}

.modal.fade .modal-dialog {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
}

.modal.in .modal-dialog {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
}

.modal-backdrop.in {
  opacity: 0.25;
}

.modal-register .modal-footer {
  text-align: center;
  margin-bottom: 25px;
  padding: 20px 0 15px;
}
.modal-register .modal-footer span {
  width: 100%;
}

.modal-header:after {
  display: table;
  content: " ";
}

.modal-header:before {
  display: table;
  content: " ";
}

/*          Changes for small display      */
@media (max-width: 767px) {
  .navbar-transparent {
    background-color: rgba(0, 0, 0, 0.45);
  }

  body {
    position: relative;
    font-size: 14px;
  }

  h6 {
    font-size: 1em;
  }

  .navbar .container {
    left: 0;
    width: 100%;
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    position: relative;
  }

  .navbar .navbar-nav .dropdown-menu {
    height: 400px;
    overflow-y: scroll;
  }

  .demo-header .motto {
    padding-top: 30% !important;
  }

  .navbar-toggle .icon-bar {
    display: block;
    position: relative;
    background: #fff;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto;
  }

  .fog-low {
    margin-bottom: -35px;
  }

  .presentation-title {
    font-size: 5em;
  }

  .presentation-subtitle {
    margin-top: 40px;
  }

  .title-brand {
    max-width: 450px;
  }
  .title-brand .type {
    font-size: 16px;
  }

  .navbar-header .navbar-toggle {
    margin-top: 12px;
    width: 40px;
    height: 40px;
  }

  .bar1,
.bar2,
.bar3 {
    outline: 1px solid transparent;
  }

  .bar1 {
    top: 0px;
    -webkit-animation: topbar-back 500ms linear 0s;
    -moz-animation: topbar-back 500ms linear 0s;
    animation: topbar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .bar2 {
    opacity: 1;
  }

  .bar3 {
    bottom: 0px;
    -webkit-animation: bottombar-back 500ms linear 0s;
    -moz-animation: bottombar-back 500ms linear 0s;
    animation: bottombar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .toggled .bar1 {
    top: 6px;
    -webkit-animation: topbar-x 500ms linear 0s;
    -moz-animation: topbar-x 500ms linear 0s;
    animation: topbar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .toggled .bar2 {
    opacity: 0;
  }

  .toggled .bar3 {
    bottom: 6px;
    -webkit-animation: bottombar-x 500ms linear 0s;
    -moz-animation: bottombar-x 500ms linear 0s;
    animation: bottombar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  @keyframes topbar-x {
    0% {
      top: 0px;
      transform: rotate(0deg);
    }
    45% {
      top: 6px;
      transform: rotate(145deg);
    }
    75% {
      transform: rotate(130deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }
  @-webkit-keyframes topbar-x {
    0% {
      top: 0px;
      -webkit-transform: rotate(0deg);
    }
    45% {
      top: 6px;
      -webkit-transform: rotate(145deg);
    }
    75% {
      -webkit-transform: rotate(130deg);
    }
    100% {
      -webkit-transform: rotate(135deg);
    }
  }
  @-moz-keyframes topbar-x {
    0% {
      top: 0px;
      -moz-transform: rotate(0deg);
    }
    45% {
      top: 6px;
      -moz-transform: rotate(145deg);
    }
    75% {
      -moz-transform: rotate(130deg);
    }
    100% {
      -moz-transform: rotate(135deg);
    }
  }
  @keyframes topbar-back {
    0% {
      top: 6px;
      transform: rotate(135deg);
    }
    45% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(5deg);
    }
    100% {
      top: 0px;
      transform: rotate(0);
    }
  }
  @-webkit-keyframes topbar-back {
    0% {
      top: 6px;
      -webkit-transform: rotate(135deg);
    }
    45% {
      -webkit-transform: rotate(-10deg);
    }
    75% {
      -webkit-transform: rotate(5deg);
    }
    100% {
      top: 0px;
      -webkit-transform: rotate(0);
    }
  }
  @-moz-keyframes topbar-back {
    0% {
      top: 6px;
      -moz-transform: rotate(135deg);
    }
    45% {
      -moz-transform: rotate(-10deg);
    }
    75% {
      -moz-transform: rotate(5deg);
    }
    100% {
      top: 0px;
      -moz-transform: rotate(0);
    }
  }
  @keyframes bottombar-x {
    0% {
      bottom: 0px;
      transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      transform: rotate(-145deg);
    }
    75% {
      transform: rotate(-130deg);
    }
    100% {
      transform: rotate(-135deg);
    }
  }
  @-webkit-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -webkit-transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      -webkit-transform: rotate(-145deg);
    }
    75% {
      -webkit-transform: rotate(-130deg);
    }
    100% {
      -webkit-transform: rotate(-135deg);
    }
  }
  @-moz-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -moz-transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      -moz-transform: rotate(-145deg);
    }
    75% {
      -moz-transform: rotate(-130deg);
    }
    100% {
      -moz-transform: rotate(-135deg);
    }
  }
  @keyframes bottombar-back {
    0% {
      bottom: 6px;
      transform: rotate(-135deg);
    }
    45% {
      transform: rotate(10deg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      transform: rotate(0);
    }
  }
  @-webkit-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -webkit-transform: rotate(-135deg);
    }
    45% {
      -webkit-transform: rotate(10deg);
    }
    75% {
      -webkit-transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      -webkit-transform: rotate(0);
    }
  }
  @-moz-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -moz-transform: rotate(-135deg);
    }
    45% {
      -moz-transform: rotate(10deg);
    }
    75% {
      -moz-transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      -moz-transform: rotate(0);
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .navbar-nav {
    margin: 1px -15px;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 15px 15px 5px 50px;
  }
  .navbar-nav .open .dropdown-menu > li:first-child > a {
    padding: 5px 15px 5px 50px;
  }
  .navbar-nav .open .dropdown-menu > li:last-child > a {
    padding: 15px 15px 25px 50px;
  }

  [class*=navbar-] .navbar-nav > li > a, [class*=navbar-] .navbar-nav > li > a:hover, [class*=navbar-] .navbar-nav > li > a:focus, [class*=navbar-] .navbar-nav .active > a, [class*=navbar-] .navbar-nav .active > a:hover, [class*=navbar-] .navbar-nav .active > a:focus, [class*=navbar-] .navbar-nav .open .dropdown-menu > li > a, [class*=navbar-] .navbar-nav .open .dropdown-menu > li > a:hover, [class*=navbar-] .navbar-nav .open .dropdown-menu > li > a:focus, [class*=navbar-] .navbar-nav .navbar-nav .open .dropdown-menu > li > a:active {
    color: white;
  }
  [class*=navbar-] .navbar-nav > li > a:not(.btn),
[class*=navbar-] .navbar-nav > li > a:hover,
[class*=navbar-] .navbar-nav > li > a:focus,
[class*=navbar-] .navbar-nav .open .dropdown-menu > li > a:not(.btn),
[class*=navbar-] .navbar-nav .open .dropdown-menu > li > a:hover,
[class*=navbar-] .navbar-nav .open .dropdown-menu > li > a:focus {
    opacity: 0.7;
    background: transparent;
  }
  [class*=navbar-] .navbar-nav.navbar-nav .open .dropdown-menu > li > a:active {
    opacity: 1;
  }
  [class*=navbar-] .navbar-nav .dropdown > a:hover .caret {
    border-bottom-color: #777;
    border-top-color: #777;
  }
  [class*=navbar-] .navbar-nav .dropdown > a:active .caret {
    border-bottom-color: white;
    border-top-color: white;
  }

  .bootstrap-datetimepicker-widget {
    visibility: visible;
  }

  .dropdown-menu {
    display: none;
  }

  .navbar-fixed-top {
    -webkit-backface-visibility: hidden;
  }

  .social-line .btn {
    margin: 0 0 10px 0;
  }

  .subscribe-line .form-control {
    margin: 0 0 10px 0;
  }

  .social-line.pull-right {
    float: none;
  }

  .footer nav.pull-left {
    float: none !important;
  }

  .footer:not(.footer-big) nav > ul li {
    float: none;
  }

  .footer .footer-nav,
.footer .credits {
    margin: 0 auto;
  }

  .section-buttons .input-group {
    margin-top: 15px;
  }
  .section-buttons .form-group.has-danger .form-control-feedback {
    margin-top: 5px;
  }

  .social-area.pull-right {
    float: none !important;
  }

  .form-control + .form-control-feedback {
    margin-top: -8px;
  }

  .navbar-toggle:hover, .navbar-toggle:focus {
    background-color: transparent !important;
  }

  .btn.dropdown-toggle {
    margin-bottom: 0;
  }

  .media-post .author {
    width: 20%;
    float: none !important;
    display: block;
    margin: 0 auto 10px;
  }

  .media-post .media-body {
    width: 100%;
  }

  .modal-footer .btn-simple {
    padding: 15px;
  }

  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    border: 1px solid #dddddd;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }

  .typography-line {
    padding-left: 100px !important;
  }

  .projects-1 .project-pills .nav.nav-pills {
    display: block !important;
  }
  .projects-1 .project-pills .nav.nav-pills .nav-item:first-child .nav-link {
    border-radius: 12px 12px 0 0 !important;
    margin-left: -1px;
  }
  .projects-1 .project-pills .nav.nav-pills .nav-item.active:first-child .nav-link {
    border-radius: 12px 12px 0 0 !important;
    margin-left: -1px;
  }
  .projects-1 .project-pills .nav.nav-pills .nav-item:last-child .nav-link {
    border-radius: 0 0 12px 12px !important;
    margin-left: -1px;
  }

  .testimonials-2 .testimonials-people img,
.section-testimonials .testimonials-people img {
    display: none !important;
  }

  .presentation-page .section-cards,
.presentation-page .section-components {
    overflow: hidden;
  }
  .presentation-page .section-cards .first-card,
.presentation-page .section-components .first-card {
    top: 750px !important;
  }
  .presentation-page .section-cards .first-card .grid__link,
.presentation-page .section-components .first-card .grid__link {
    width: 200px !important;
  }
  .presentation-page .section-cards .fourth-card,
.presentation-page .section-components .fourth-card {
    top: 940px !important;
  }
  .presentation-page .section-cards .fourth-card .grid__link,
.presentation-page .section-components .fourth-card .grid__link {
    width: 200px !important;
  }
  .presentation-page .section-cards .fifth-card,
.presentation-page .section-components .fifth-card {
    top: 950px !important;
    left: 220px !important;
  }
  .presentation-page .section-cards .fifth-card .grid__link,
.presentation-page .section-components .fifth-card .grid__link {
    width: 200px !important;
  }
  .presentation-page .section-cards .sixth-card,
.presentation-page .section-components .sixth-card {
    top: 1335px !important;
    left: 220px !important;
  }
  .presentation-page .section-cards .sixth-card .grid__link,
.presentation-page .section-components .sixth-card .grid__link {
    width: 200px !important;
  }
  .presentation-page .section-cards .seventh-card,
.presentation-page .section-components .seventh-card {
    top: 1155px !important;
  }
  .presentation-page .section-cards .seventh-card .grid__link,
.presentation-page .section-components .seventh-card .grid__link {
    width: 200px !important;
  }
  .presentation-page .section-content .image-container .add-animation {
    height: 250px !important;
    width: 180px !important;
  }
  .presentation-page .section-components .image-container .components-macbook {
    width: 580px !important;
    height: 400px !important;
  }
  .presentation-page .section-components .image-container .social-img,
.presentation-page .section-components .image-container .share-btn-img {
    display: none;
  }
  .presentation-page .section-components .title {
    margin-top: -100px !important;
  }
  .presentation-page .section-examples {
    padding-top: 0 !important;
    margin-top: 10px !important;
  }
  .presentation-page .section-icons .icons-nucleo .nc-icon:not(.ninth-left-icon):not(.seventh-left-icon):not(.third-left-icon) {
    display: none !important;
  }

  #typography .typography-line .note {
    margin-left: 7px !important;
  }

  .section-nucleo-icons [class*=col-lg] {
    text-align: center !important;
    margin-bottom: 2em;
  }
}
@media screen and (max-width: 991px) {
  .navbar-collapse {
    display: block;
    position: fixed;
    top: 0;
    height: 100%;
    width: 230px;
    right: 0;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    border-left: 1px solid #CCC5B9;
    padding-right: 0px;
    padding-left: 40px;
    padding-top: 15px;
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .navbar-collapse ul {
    position: relative;
    z-index: 3;
    height: 95%;
  }
  .navbar-collapse .navbar-nav > .nav-item:last-child {
    border-bottom: 0;
  }
  .navbar-collapse .navbar-nav > .nav-item > .nav-link {
    margin: 0px 0px;
    color: #9A9A9A !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5em;
    padding: 15px 0;
  }
  .navbar-collapse .navbar-nav > .nav-item > .nav-link:hover, .navbar-collapse .navbar-nav > .nav-item > .nav-link:active {
    color: #403D39 !important;
  }
  .navbar-collapse::after {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #FFFCF5;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
    display: block;
    content: "";
    z-index: 1;
  }
  .navbar-collapse.has-image::after {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(17, 17, 17, 0.8);
    display: block;
    content: "";
    z-index: 1;
  }

  .nav-open .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    display: block;
  }
  .nav-open .wrapper {
    left: 0;
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform: translate3d(-230px, 0, 0);
    -o-transform: translate3d(-230px, 0, 0);
    -ms-transform: translate3d(-230px, 0, 0);
    transform: translate3d(-230px, 0, 0);
  }
  .nav-open .navbar-translate {
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform: translate3d(-230px, 0, 0);
    -o-transform: translate3d(-230px, 0, 0);
    -ms-transform: translate3d(-230px, 0, 0);
    transform: translate3d(-230px, 0, 0);
  }

  .wrapper .navbar-collapse {
    display: none;
  }

  .fixed-top .navbar-collapse {
    background-color: #FF8F5E;
    float: left;
  }

  .dropdown.show .dropdown-menu,
.dropdown .dropdown-menu {
    background-color: transparent;
    border: 0;
    transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: auto;
    border-radius: 0;
  }
  .dropdown.show .dropdown-menu .dropdown-item:hover,
.dropdown.show .dropdown-menu .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    border-radius: 0;
  }
  .dropdown.show .dropdown-menu .dropdown-item a:hover, .dropdown.show .dropdown-menu .dropdown-item a:focus,
.dropdown .dropdown-menu .dropdown-item a:hover,
.dropdown .dropdown-menu .dropdown-item a:focus {
    color: #403D39;
  }
  .dropdown.show .dropdown-menu:before, .dropdown.show .dropdown-menu:after,
.dropdown .dropdown-menu:before,
.dropdown .dropdown-menu:after {
    display: none;
  }

  .dropdown .dropdown-menu {
    display: none;
  }
  .dropdown.show .dropdown-menu {
    display: block;
  }

  .navbar-translate {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .wrapper {
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    left: 0;
    background-color: white;
  }
}
@media screen and (min-width: 767px) {
  .section-sections .section-cols {
    position: relative;
    z-index: 1;
  }
  .section-sections .section-cols .row:first-of-type {
    margin-top: 50px;
    margin-right: -120px;
    margin-left: -15px;
    -webkit-transform: translate3d(-80px, 0, 0);
    -moz-transform: translate3d(-80px, 0, 0);
    -o-transform: translate3d(-80px, 0, 0);
    -ms-transform: translate3d(-80px, 0, 0);
    transform: translate3d(-80px, 0, 0);
  }
  .section-sections .section-cols .row:nth-of-type(2) {
    margin-left: -100px;
    margin-right: -15px;
    transform: translateX(80px);
  }
  .section-sections .section-cols .row:nth-of-type(3) {
    margin-right: -120px;
    margin-left: 0px;
    transform: translateX(-120px);
  }
  .section-sections .section-cols .row:nth-of-type(4) {
    margin-right: -100px;
    margin-left: -15px;
    transform: translateX(-50px);
  }
}
@media screen and (min-width: 991px) {
  .burger-menu .navbar-collapse {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: 230px;
    right: 0px;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    border-left: 1px solid #CCC5B9;
    padding-right: 0px;
    padding-left: 40px;
    padding-top: 15px;
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .burger-menu .navbar-collapse .navbar-nav .nav-item:last-child {
    border-bottom: 0;
  }
  .burger-menu .navbar-collapse .navbar-nav {
    height: 100%;
    z-index: 2;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .burger-menu .navbar-collapse::after {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #FFFCF5;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
    display: block;
    content: "";
    z-index: 1;
  }
  .burger-menu .navbar-collapse.has-image::after {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(17, 17, 17, 0.8);
    display: block;
    content: "";
    z-index: 1;
  }
  .burger-menu .navbar .container .navbar-toggler {
    display: block;
    margin-top: 20px;
  }
  .burger-menu .navbar-translate {
    width: 100%;
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .burger-menu .wrapper {
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    left: 0;
    background-color: white;
  }
  .burger-menu .body .navbar-collapse.collapse {
    height: 100vh !important;
  }
  .burger-menu .navbar-collapse:before, .burger-menu .navbar-collapse:after,
.burger-menu .navbar-nav:before,
.burger-menu .navbar-nav:after {
    display: table;
    content: " ";
  }
  .nav-open .burger-menu .navbar-collapse.collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .nav-open .burger-menu .wrapper {
    left: 0;
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform: translate3d(-230px, 0, 0);
    -o-transform: translate3d(-230px, 0, 0);
    -ms-transform: translate3d(-230px, 0, 0);
    transform: translate3d(-230px, 0, 0);
  }
  .nav-open .burger-menu .navbar-translate {
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform: translate3d(-230px, 0, 0);
    -o-transform: translate3d(-230px, 0, 0);
    -ms-transform: translate3d(-230px, 0, 0);
    transform: translate3d(-230px, 0, 0);
  }
  .burger-menu .dropdown.show .dropdown-menu,
.burger-menu .dropdown .dropdown-menu {
    background-color: transparent;
    border: 0;
    transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: auto;
    border-radius: 0;
  }
  .burger-menu .dropdown.show .dropdown-menu .dropdown-item:hover,
.burger-menu .dropdown.show .dropdown-menu .dropdown-item:focus,
.burger-menu .dropdown .dropdown-menu .dropdown-item:hover,
.burger-menu .dropdown .dropdown-menu .dropdown-item:focus {
    background-color: transparent;
    border-radius: 0;
  }
  .burger-menu .dropdown.show .dropdown-menu .dropdown-item a:hover, .burger-menu .dropdown.show .dropdown-menu .dropdown-item a:focus,
.burger-menu .dropdown .dropdown-menu .dropdown-item a:hover,
.burger-menu .dropdown .dropdown-menu .dropdown-item a:focus {
    color: #403D39;
  }
  .burger-menu .dropdown.show .dropdown-menu:before, .burger-menu .dropdown.show .dropdown-menu:after,
.burger-menu .dropdown .dropdown-menu:before,
.burger-menu .dropdown .dropdown-menu:after {
    display: none;
  }
  .burger-menu .dropdown .dropdown-menu {
    display: none;
  }
  .burger-menu .dropdown.show .dropdown-menu {
    display: block;
  }
}
@media screen and (max-width: 1025px) {
  .input-group .input-group-addon {
    padding-right: 10px;
  }
}
ngb-datepicker {
  border: 0 none !important;
  border-radius: 12px !important;
  display: block !important;
  margin-top: 10px !important;
  padding: 0px !important;
  position: absolute !important;
  -webkit-box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(17, 16, 15, 0.1);
  box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(17, 16, 15, 0.1);
}
ngb-datepicker *:focus {
  outline: none !important;
}
ngb-datepicker .ngb-dp-header {
  border-bottom: none !important;
  background-color: #FFFFFF !important;
}
ngb-datepicker .ngb-dp-header ngb-datepicker-navigation button {
  padding: 1px;
  text-align: center;
  z-index: 1;
  line-height: 31px;
}
ngb-datepicker .ngb-dp-header ngb-datepicker-navigation button .ngb-dp-navigation-chevron {
  border-radius: 4px;
  height: unset !important;
  width: unset !important;
  font-size: 12px;
  margin-left: 0.15rem;
  border-radius: 50%;
  text-align: center;
  border-width: 0px;
  transform: none !important;
}
ngb-datepicker .ngb-dp-header ngb-datepicker-navigation button .ngb-dp-navigation-chevron:before {
  content: "" !important;
  border: none !important;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: anitialised;
  transform: none !important;
  color: black;
}
ngb-datepicker .ngb-dp-header ngb-datepicker-navigation .right .ngb-dp-navigation-chevron:before {
  content: "" !important;
}
ngb-datepicker .ngb-dp-header ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select {
  border: none !important;
  background: transparent !important;
  -webkit-appearance: menulist !important;
}
ngb-datepicker .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-weekday {
  border-bottom: 1px solid #E3E3E3;
  font-size: 12px;
  text-transform: uppercase;
  color: #9A9A9A !important;
  font-weight: 400;
  padding-top: 0px;
  font-style: normal !important;
}
ngb-datepicker .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-day {
  padding: 1px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  font-family: "Montserrat", "Helvetica", Arial, sans-serif;
  font-weight: 300;
}
ngb-datepicker .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-day .btn-light {
  border-radius: 50% !important;
}
ngb-datepicker .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-day .btn-light:hover {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
}
ngb-datepicker .ngb-dp-month ngb-datepicker-month .ngb-dp-week .ngb-dp-day div[ng-reflect-selected=true] {
  background-color: #51cbce !important;
}
ngb-datepicker:before {
  border-bottom: 11px solid #F1EAE0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  right: auto;
  top: -12px;
}
ngb-datepicker:after {
  border-bottom: 11px solid #FFFFFF;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  right: auto;
  top: -11px;
}

.btn-twitter {
  color: #55acee !important;
}
.btn-twitter:hover, .btn-twitter:focus, .btn-twitter:active {
  color: #55acee !important;
}

.btn-facebook {
  color: #3b5998 !important;
}
.btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active {
  color: #3b5998 !important;
}

.btn-google {
  color: #dd4b39 !important;
}
.btn-google:hover, .btn-google:focus, .btn-google:active {
  color: #dd4b39 !important;
}

.btn-linkedin {
  color: #0976b4 !important;
}
.btn-linkedin:hover, .btn-linkedin:focus, .btn-linkedin:active {
  color: #0976b4 !important;
}

.btn-twitter-bg {
  background-color: #55acee !important;
  border-color: #55acee !important;
}
.btn-twitter-bg:hover {
  background-color: #3ea1ec !important;
  border-color: #3ea1ec !important;
}

.btn-facebook-bg {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
}
.btn-facebook-bg:hover {
  background-color: #344e86 !important;
  border-color: #344e86 !important;
}

.btn-google-bg {
  background-color: #dd4b39 !important;
  border-color: #dd4b39 !important;
}
.btn-google-bg:hover {
  background-color: #d73925 !important;
  border-color: #d73925 !important;
}

.btn-github-bg {
  background-color: #767676 !important;
  border-color: #767676 !important;
}
.btn-github-bg:hover {
  background-color: dimgray !important;
  border-color: dimgray !important;
}

.landing-alert {
  margin-bottom: 0;
}

.page-header {
  background-color: #B2AFAB;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  max-height: 999px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  /* Center vertically */
  align-items: center;
  /*Center horizontaly */
  justify-content: center;
  /*Center horizontaly ie */
  -ms-flex-pack: center;
}
.page-header .filter::after {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.page-header.page-header-small {
  min-height: 65vh !important;
  max-height: 700px;
}
.page-header.page-header-xs {
  min-height: 40vh !important;
}
.page-header.section-dark .content-center,
.page-header.section-dark .category-absolute {
  z-index: 1;
}

.page-header .motto {
  text-align: left;
  z-index: 3;
  color: #fff;
  position: relative;
}

.landing-section {
  padding: 100px 0;
  z-index: 1;
}

.section-buttons {
  z-index: 1;
}

.landing-section .btn-simple {
  padding: 0;
}

.landing-section .column {
  padding: 0 75px 0 25px;
}

.team-player .img-circle, .team-player .img-thumbnail {
  display: block;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}

.contact-form {
  margin-top: 30px;
}

.contact-form label {
  margin-top: 15px;
}

.contact-form .btn {
  margin-top: 30px;
}

.navbar-relative {
  position: relative;
  z-index: 2;
}

#register-navbar a {
  color: #FFF;
}

.register-background .container {
  margin-top: 11%;
  position: relative;
  z-index: 3;
}

.register-footer {
  bottom: 20px;
  position: absolute;
  z-index: 1;
  width: 100%;
  background: transparent;
  color: #FFFFFF;
  left: 0;
}

.register-footer .fa-heart {
  color: #EB5E28;
}

.register-card label {
  margin-top: 15px;
}

.register-card .title {
  color: #B33C12;
  text-align: center;
}

.register-card .btn {
  margin-top: 30px;
}

.register-card .forgot {
  text-align: center;
}

.profile-content {
  position: relative;
}

.owner {
  text-align: center;
}

.owner .avatar {
  padding: 15px;
  max-width: 180px;
  margin: -85px auto 0;
  display: inline-block;
}

.owner .name h4 {
  margin-top: 10px;
}

.profile-tabs {
  margin: 50px 0;
  min-height: 300px;
}

#following h3 {
  margin: 20px 0 40px 0;
}

#follows .follows .unfollow .checkbox {
  margin-top: -15px;
}

#follows .follows h6 {
  margin-top: 15px;
}

.follows hr {
  margin-top: 10px;
}

.title-brand {
  max-width: 730px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  color: #FFFFFF;
  display: block;
}
.title-brand .type {
  position: absolute;
  font-size: 20px;
  background: #132026;
  padding: 6px 10px;
  border-radius: 4px;
  top: 0;
  font-weight: 600;
  margin-top: 10px;
  right: -15px;
}

.presentation-title {
  font-size: 8em;
  font-weight: 700;
  margin: 0;
  color: #FFFFFF;
  background: -moz-linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
  background: -webkit-linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
  background: -ms-linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
  background: -o-linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
  background: linear-gradient(to bottom, #FFFFFF 35%, #4e6773 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.presentation-subtitle {
  font-size: 1.7em;
  color: #FFFFFF;
}

.category-absolute {
  position: absolute;
  text-align: center;
  top: 100vh;
  margin-top: -60px;
  padding: 0 15px;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
}
.category-absolute .creative-tim-logo {
  max-width: 140px;
  top: -2px;
  left: 3px;
  position: relative;
}

.fog-low {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: -35%;
  margin-bottom: -50px;
  width: 110%;
  opacity: 0.85;
}
.fog-low img {
  width: 100%;
}
.fog-low.right {
  margin-left: 30%;
  opacity: 1;
}

.angular-logo {
  max-width: 90px;
  margin: -50px auto 45px;
}
.angular-logo img {
  width: 100%;
}

.moving-clouds {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 250.625em;
  height: 43.75em;
  -webkit-animation: cloudLoop 80s linear infinite;
  animation: cloudLoop 80s linear infinite;
}

@keyframes cloudLoop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
.following img {
  max-width: 70px;
}

.ie-background .page-header .content-center {
  top: 50%;
  left: 50%;
  position: absolute;
  -ms-transform: translate(-50%, -75%);
}
.ie-background .page-header .content-center .title-brand .presentation-title {
  background: none;
}

@media (max-width: 500px) {
  .presentation-title {
    font-size: 5em;
  }
}